import 'alpinejs';

window.cartBottomMessage = function (orderMinimumEnabled, orderMinimum, freeShippingThreshold) {
    return {
        minimumLock: orderMinimumEnabled,
        bottomMessage: 'no-minimum',
        missingValueForFreeShipping: 0,

        forceNoMessage() {
            this.bottomMessage = '';
        },
        processBottomMessage(productsWoVat, cartFlowType = '') {
            this.minimumLock = false;
            if (cartFlowType === 'pallet') {
                this.bottomMessage = 'pallet';
            } else {
                if (orderMinimumEnabled && productsWoVat < orderMinimum) {
                    this.bottomMessage = 'no-minimum';
                    this.minimumLock = true;
                } else if (productsWoVat < freeShippingThreshold) {
                    this.bottomMessage = 'no-free-shipping';
                    this.missingValueForFreeShipping = freeShippingThreshold - productsWoVat;
                    this.missingValueForFreeShipping = utils.formatPrice(this.missingValueForFreeShipping);
                } else {
                    this.bottomMessage = 'free-shipping';
                }
            }
        }
    };
};
window.cartSummary = function () {
    return {
        redrawCoupon(cart, $dispatch) {
            const coupon = cart.coupon;

            $dispatch('coupon-cta-update', {
                show: !!coupon.validationErrorMessage,
                message: coupon.validationErrorMessage
            });

            const $refs = this.$refs;
            utils.setVisible(utils.querySelectorAll('p.product-taxable-discount-row'), coupon.couponValid);
            $refs.productTaxableDiscount.innerHTML = coupon.couponValid ?
                ('-' + utils.formatPrice(coupon.productTaxableDiscount) + ' &euro;') : '';
        },
        redrawTotals(cart) {
            const $refs = this.$refs;
            ['products', 'shipping', 'dedicatedTransport', 'deliveryToFloor', 'total', 'productsFull'].forEach(rowName => {
                ['WoVat', 'Vat', 'WVat'].forEach(priceName => {
                    const valueName = rowName + priceName;
                    const value = cart.total[valueName];
                    if (value === undefined) return;
                    if (rowName === 'productsFull' && !value) return;

                    ['', '-m'].forEach(responsive => {
                        const refName = rowName + priceName + responsive;
                        if ($refs[refName])
                            $refs[refName].innerHTML = utils.formatPrice(value) + ' &euro;';
                    });
                });
            });
            // Letame puro, e me ne vergogno
            if ($refs.shippingWoVatPallet)
                $refs.shippingWoVatPallet.innerHTML = utils.formatPrice(cart.total.shippingWoVat) + ' &euro;';
        }
    }
}
window.cartDeliveryToFloor = function (deliveryToFloor) {
    const deliveryToFloorValue = deliveryToFloor === true ? 'yes' : (deliveryToFloor === false ? 'no' : '');
    return {
        deliveryToFloor: deliveryToFloor === true,
        deliveryToFloorValue: deliveryToFloorValue,

        redrawTransport(cart) {
            const $refs = this.$refs;
            if ($refs.productsWeight && cart.total.totalProductsWeight)
                $refs.productsWeight.innerHTML = utils.formatOneDecimal(cart.total.totalProductsWeight) + ' Kg';
            if ($refs.deliveryToFloorWoVat2 && cart.total.deliveryToFloorWoVat)
                $refs.deliveryToFloorWoVat2.innerHTML = utils.formatPrice(cart.total.deliveryToFloorWoVat) + ' &euro;';
        }
    };
};

window.buyable = {};

window.buyable.cart = function (userLoggedIn, orderMinimumEnabled, orderMinimum, freeShippingThreshold, realCart) {
    return {
        ...window.cartBottomMessage(orderMinimumEnabled, orderMinimum, freeShippingThreshold),
        ...window.cartDeliveryToFloor(realCart.deliveryToFloor),
        ...window.cartSummary(),
        productsWoVat: realCart.total.productsWoVat,
        selectQuantities: {},
        palletProvinceCode: realCart.palletDeliveryProvinceCode,
        palletProvinceName: '',

        couponBanner: window.couponBanner,
        initCart($watch, $dispatch, unavailableProductNames) {
            this.redrawCart(realCart, $dispatch);
            console.log(realCart.flowType)
            this.processBottomMessage(this.productsWoVat, realCart.flowType);
            this.initSelectInputs($watch, $dispatch);
            this.initDeliveryTypePallet($watch, $dispatch);
            this.initUnavailableProducts(unavailableProductNames, $dispatch);
            this.buildGtmCheckout();
        },
        initSelectInputs($watch, $dispatch) {
            const self = this;
            Array.apply(null, document.querySelectorAll('.full-cart-container select.quantity')).forEach(row => {
                const productCode = row.dataset.code;
                self.selectQuantities['_' + productCode] = parseInt(row.dataset.quantity);
                $watch('selectQuantities._' + productCode, (quantity) => {
                    CartApi.updateProductIntoCart(productCode, parseInt(quantity), $dispatch);
                });
            });
        },
        initDeliveryTypePallet($watch, $dispatch) {
            if (realCart.flowType === 'pallet') {
                const self = this;
                setTimeout(() => {
                    self.redrawPallet();
                }, 0);
                $watch('palletProvinceCode', async value => {
                    if (value !== '') {
                        const options = document.querySelector('select#provincia > .first');
                        if (!options.classList.contains('hidden'))
                            options.classList.add('hidden');

                        await CartApi.pallet.setProvince(value, $dispatch);
                    }
                });
            }
        },
        initUnavailableProducts(unavailableProductNames, $dispatch) {
            if (unavailableProductNames.length > 0) {
                setTimeout(function () {
                    modal.openDefinedModal($dispatch, 'cart-removed-products', {
                        products: unavailableProductNames
                    }).then((detail) => {
                        if (detail.action)
                            CartApi.resetUnavailableProducts($dispatch);
                    });
                }, 0);
            }
        },
        buildGtmCheckout() {
            const products = document.querySelectorAll("div.checkout-product-row");
            const previewTexts = document.querySelectorAll("div.checkout-product-row div.preview-text");
            let index = 0;
            const gtmProducts = Array.from(products).map(elem => {
                return {
                    id: elem.dataset.code,
                    quantity: elem.dataset.quantity,
                    price: elem.dataset.price.replace('.', '').replace(',', '.'),
                    name: previewTexts[index++].innerHTML,
                };
            });
            gtmUtils.checkoutStep(1, gtmProducts);
        },

        async goToCheckout($dispatch) {
            if (realCart.flowType === 'pallet') {
                if (!this.palletProvinceCode) {
                    await modal.openDefinedModal($dispatch, 'pallet-must-select-province')
                    return;
                }
                for (let modalName of [
                    'cart-pallet-delivery-method', 'cart-pallet-payment-method'
                ]) {
                    let detail = await modal.openDefinedModal($dispatch, modalName);
                    if (!detail.action)
                        return;
                }
                document.location = '/checkout/indirizzo-di-fatturazione';
            } else {
                if (orderMinimumEnabled && this.productsWoVat < orderMinimum) {
                    modal.openDefinedModal($dispatch, 'cart-order-minimum-not-satisfied', {orderMinimum});
                } else if (this.deliveryToFloorValue === '') {
                    modal.openDefinedModal($dispatch, 'cart-request-delivery-to-floor-selection');
                } else {
                    document.location = '/checkout/indirizzo-di-fatturazione';
                }
            }
        },
        saveCart($dispatch) {
            if (userLoggedIn) {
                let defaultCartName = "Carrello salvato del " + (new Date()).format("yyyy/MM/dd hh:mm:ss");
                modal.openInteractiveModal($dispatch, 'cart-save-cart', {cartName: defaultCartName})
                    .then(detail => {
                        CartApi.saveCart(detail.cartName);
                    });
            } else {
                modal.openDefinedModal($dispatch, 'cart-login-before-save-cart');
            }
        },
        updateCartData({cart, eventName, eventProduct, actualQuantity, quantityVariation}, $dispatch) {
            console.log(JSON.parse(JSON.stringify(cart)));
            this.productsWoVat = cart.total.productsWoVat;
            if (eventName === 'set-product-into-cart') {
                this.makeGtmCartProductEvent(eventProduct, quantityVariation);
                this.setProductQuantity(eventProduct, actualQuantity);
            } else if (eventName === 'remove-product-from-cart') {
                this.makeGtmCartProductEvent(eventProduct, quantityVariation);
                document.location.reload();
                return;
            } else if (eventName === 'set-delivery-to-floor') {
                this.deliveryToFloor = cart.deliveryToFloor;
                this.deliveryToFloorValue = cart.deliveryToFloor ? 'yes' : 'no';
                gtmUtils.checkoutOption(1, "consegna al piano " + (cart.deliveryToFloor ? 1 : 0));
            } else if (eventName === 'set-pallet-province') {
            }

            this.processBottomMessage(this.productsWoVat, cart.flowType);
            this.redrawCart(cart, $dispatch);
        },


        userIncrementProductQuantity(productCode, $dispatch) {
            const quantity = this.getProductQuantity(productCode);
            const finalQuantity = (quantity + 1);
            CartApi.updateProductIntoCart(productCode, finalQuantity, $dispatch);
            utils.resetTextSelection();
        },
        userDecrementProductQuantity(productCode, $dispatch) {
            const quantity = this.getProductQuantity(productCode);
            if (quantity - 1 <= 0) {
                this.deleteProductRow(productCode, $dispatch);
            } else {
                const finalQuantity = (quantity - 1);
                CartApi.updateProductIntoCart(productCode, finalQuantity, $dispatch);
            }
            utils.resetTextSelection();
        },
        deleteProductRow(productCode, $dispatch) {
            modal.openDefinedModal($dispatch, 'cart-confirm-remove')
                .then(detail => {
                    if (detail.action === true)
                        CartApi.removeProductFromCart(productCode, $dispatch);
                });
        },
        setDeliveryToFloor(set, $dispatch) {
            CartApi.setDeliveryToFloor(set, $dispatch);
        },


        redrawCart(cart, $dispatch) {
            this.redrawTransport(cart);
            this.redrawTotals(cart);
            this.redrawCoupon(cart, $dispatch);
            this.redrawPallet();

            const $refs = this.$refs;
            cart.products.forEach(prod => {
                const code = prod.productCode;

                const discountClassList = $refs['discountBlock-' + code].classList;
                const singleProdDiscountClassList = $refs['singleDiscountBlock-' + code].classList;
                if (prod.discountPct > 0) {
                    discountClassList.remove('hidden')
                    singleProdDiscountClassList.remove('hidden')
                } else {
                    if (!discountClassList.contains('hidden'))
                        discountClassList.add('hidden');
                    if (!singleProdDiscountClassList.contains('hidden'))
                        singleProdDiscountClassList.add('hidden');
                }

                $refs['listDiscountPct-' + code].innerHTML = utils.formatOneDecimal(prod.discountPct);
                $refs['listPriceWoVat-' + code].innerHTML = utils.formatPrice(prod.listPriceWoVat);
                $refs['finalPriceWoVat-' + code].innerHTML = utils.formatPrice(prod.finalPriceWoVat);

                $refs['discountPct-' + code].innerHTML = utils.formatOneDecimal(prod.discountPct);
                $refs['rowListPriceWoVat-' + code].innerHTML = utils.formatPrice(prod.rowListPriceWoVat);
                $refs['rowFinalPriceWoVat-' + code].innerHTML = utils.formatPrice(prod.rowFinalPriceWoVat);
                $refs['rowFinalPriceWoVat-' + code + '-c'].innerHTML = utils.formatPrice(prod.rowFinalPriceWoVat);
                $refs['rowFinalPriceWVat-' + code].innerHTML = utils.formatPrice(prod.rowFinalPriceWVat);
                $refs['rowFinalPriceWVat-' + code + '-c'].innerHTML = utils.formatPrice(prod.rowFinalPriceWVat);
            });
        },
        redrawPallet() {
            if (realCart.flowType === 'pallet') {
                const select = document.querySelector("select#provincia");
                const selectedOption = select.options[select.selectedIndex];
                this.palletProvinceName = selectedOption.innerHTML;
            }
        },
        getProductQuantity(productCode) {
            const elem = this.$refs['product-quantity-' + productCode];
            const html = elem.innerHTML;
            return parseInt(html);
        },
        setProductQuantity(productCode, quantity) {
            const elem = this.$refs['product-quantity-' + productCode];
            if (elem.tagName.toLowerCase() === 'div') {
                elem.innerHTML = quantity;
            } else {
                const currentQuantity = this.selectQuantities['_' + productCode];
                if (parseInt(currentQuantity) !== parseInt(quantity))
                    this.selectQuantities['_' + productCode] = quantity;
            }
        },


        navigateBack() {
            if (utils.isValidInternalReferrer(['/carrello.jsp', '/checkout/indirizzo-di-fatturazione']))
                history.back();
            else
                document.location = '/';
        },

        makeGtmCartProductEvent(productCode, quantityVariation) {
            const productName = document.querySelector('div[data-code=\'' + productCode + '\'] div.preview-text span').innerHTML;
            const priceElem = document.querySelector('div[data-code=\'' + productCode + '\']');
            const price = parseFloat(priceElem.dataset.price);
            gtmUtils.cartProductEvent({
                id: productCode,
                name: productName,
                price
            }, quantityVariation);
        }
    }
}

window.buyable.savedCart = function (savedCartDeprecatedId, cartIsEmpty, orderMinimumEnabled, orderMinimum, freeShippingThreshold, productsWoVat) {
    return {
        ...window.cartBottomMessage(orderMinimumEnabled, orderMinimum, freeShippingThreshold),
        productsWoVat: productsWoVat,

        initSavedCart() {
            console.log("savedCart",)
            this.processBottomMessage(this.productsWoVat);
        },
        goToCheckout($dispatch) {
            if (!cartIsEmpty) {
                modal.openDefinedModal($dispatch, 'saved-cart-replace-or-add-products')
                    .then(async detail => {
                        if (['add', 'set'].includes(detail.action)) {
                            await CartApi.savedCart.restore(savedCartDeprecatedId, detail.action, $dispatch);
                            document.location = '/carrello.jsp';
                        }
                    });
            } else {
                CartApi.savedCart.restore(savedCartDeprecatedId, 'set', $dispatch)
                    .then(() => {
                        document.location = '/carrello.jsp';
                    });
            }
        },
        deleteCart($dispatch) {
            modal.openDefinedModal($dispatch, 'saved-cart-confirm-delete')
                .then(async detail => {
                    if (detail.action === true) {
                        await CartApi.savedCart.delete(savedCartDeprecatedId, $dispatch);
                        document.location = '/view_ordini_sospesi.jsp';
                    }
                });
        },

        navigateBack() {
            document.location = '/view_ordini_sospesi.jsp';
        }
    }
}

window.buyable.quote = function (quoteDeprecatedId, quoteNumber, deliveryToFloor, expired, expiring) {
    return {
        ...window.cartBottomMessage(false, 0, 0),
        ...window.cartDeliveryToFloor(deliveryToFloor),
        ...window.cartSummary(),

        initQuote() {
            this.forceNoMessage();
        },
        goToCheckout($dispatch) {
            if (expired) {
                modal.openDefinedModal($dispatch, 'quote-expired');
                return;
            }
            if (this.deliveryToFloorValue === '') {
                modal.openDefinedModal($dispatch, 'cart-request-delivery-to-floor-selection');
            } else {
                document.location = '/checkout/indirizzo-di-fatturazione' + '?num-preventivo=' + quoteNumber;
            }
        },


        updateQuoteData({quote}) {
            console.log(JSON.parse(JSON.stringify(quote)));
            this.deliveryToFloor = quote.deliveryToFloor;
            this.deliveryToFloorValue = quote.deliveryToFloor ? 'yes' : 'no';

            this.redrawTotals(quote);
            this.redrawTransport(quote);
        },

        setDeliveryToFloor(set, $dispatch) {
            CartApi.quote.setDeliveryToFloor(quoteDeprecatedId, set, $dispatch);
        },

        navigateBack() {
            document.location = '/preventivi.jsp';
        }
    }
}

window.buyable.order = function (orderDeprecatedId, orderNumber, cartIsEmpty, isFiliale = false, codFiliale = null) {
    return {
        ...window.cartBottomMessage(false, 0, 0),

        initOrder() {
            this.forceNoMessage();
        },
        goToCheckout($dispatch) {
            document.location = '/checkout/modalita-di-pagamento' + '?num-ordine=' + orderNumber;
        },
        abortOrder($dispatch) {
            modal.openDefinedModal($dispatch, 'order-confirm-abort')
                .then(async detail => {
                    if (detail.action === true) {
                        await CartApi.order.abortOrder(orderDeprecatedId, $dispatch);
                        document.location = '/storico_ordini.jsp';
                    }
                });
        },
        buyAgain($dispatch) {
            if (!cartIsEmpty) {
                modal.openDefinedModal($dispatch, 'order-replace-or-add-products')
                    .then(async detail => {
                        if (['add', 'set'].includes(detail.action)) {
                            await CartApi.order.buyAgain(orderDeprecatedId, detail.action, $dispatch);
                            document.location = '/carrello.jsp';
                        }
                    });
            } else {
                CartApi.order.buyAgain(orderDeprecatedId, 'set', $dispatch)
                    .then(() => {
                        document.location = '/carrello.jsp';
                    });
            }
        },

        navigateBack() {
            if (isFiliale) {
                document.location = '/storico_ordini_filiale.jsp?brch=' + codFiliale;
            } else {
                document.location = '/storico_ordini.jsp';
            }
        }
    }
}
