import 'alpinejs';

window.payment = function () {
    return {
        paymentsPostumi: ["019"],
        paymentSelected: "",
        defaultPayment: "",
        modalOpened: false,
        initPayment(defaultPayment) {
            this.defaultPayment = defaultPayment;
            this.paymentSelected = defaultPayment;
            console.log("initPayment", this.paymentSelected, this.defaultPayment)
        },
        execPayment($dispatch) {
            console.log("execPayment", this.paymentSelected, this.defaultPayment)
            if (this.paymentsPostumi.includes(this.defaultPayment) && (this.paymentSelected !== this.defaultPayment) && this.modalOpened === false) {
                let paymentSelected = document.getElementById('label-payment-' + this.paymentSelected).innerHTML;
                this.modalOpened = true;
                modal.openModal($dispatch, 'payment-selected-confirmation', {
                    secondPayment: paymentSelected
                })
            } else {
                this.$refs.form.submit();
            }
        },
    }
};

