import 'alpinejs';
import Cookies from "js-cookie";

window.tooltipBase = () => {
    return {
        tp_animation: {
            ['x-transition:enter']: 'transition ease-out duration-100',
            ['x-transition:enter-start']: 'transform opacity-0 scale-95',
            ['x-transition:leave']() {
                return 'transition ease-in duration-75';
            },
            ['x-transition:leave-end']() {
                return 'transform opacity-0 scale-95';
            }
        }
    }
}

window.productCommon = function () {
    return {
        toggleFav($dispatch) {
            const self = this;
            const setFav = !self.isFav ? 1 : 0;
            fetch('/api21/user-fav/set?product_code=' + self.productCode + '&set=' + setFav)
                .then(() => {
                    this.isFav = setFav
                    this.favTpOpen = setFav === 1;
                    $dispatch('fav-count', {count: setFav ? 1 : -1});

                    gtmUtils.toggleFav(self.productCode, setFav);
                })
                .catch(utils.errorLog);
        },
        closeFav() {
            this.favTpOpen = false;
        },
        favTpOpen: false
    };
}
window.productPreview = function (pageClass, productCode, isFav) {
    if (pageClass === 'user-favorites')
        return productFavPreview(productCode);
    else
        return {
            productCode,
            isFav,
            initProductPreview() {
                if (this.$refs.prodPrice && this.$refs.prodPrice.innerText.length > 9 && this.$refs.prodPrice.classList.contains('discount')) {
                    this.$refs.prodPrice.classList.add('price-long');
                }
                if (this.$refs.prodDiscountedPrice && this.$refs.prodDiscountedPrice.innerText.length > 9) {
                    this.$refs.prodDiscountedPrice.classList.add('price-long');
                }
            },
            copyProductNameToClipboard(event) {
                const el = event.target;
                const productName = el.dataset.titletext;

                navigator.clipboard.writeText(productName.toLowerCase());
                setTimeout(() => {
                    el.innerText = 'Copied!';
                    setTimeout(() => {
                        el.innerText = '📋';
                    }, 750);
                });
            },
            ...window.productCommon()
        }
}
window.productFavPreview = function (productCode) {
    return {
        productCode,
        isFav: true,
        initProductPreview($watch, $dispatch) {
            this.$dispatch = $dispatch;
            const target = this.$refs.main;
            $watch('isFav', value => {
                if (!value) {
                    const source = document.querySelector('div.fav-remove-layer.source');
                    const layer = source.cloneNode(true);
                    layer.classList.remove('source');
                    layer.classList.add('flex');
                    target.append(layer);
                } else {
                    const layer = document.querySelector('.c' + productCode + ' .fav-remove-layer');
                    layer.parentNode.removeChild(layer);
                }
            });

        },
        abortRemove() {
            if (!this.isFav)
                this.toggleFav(this.$dispatch);
        },
        ...window.productCommon()
    }
}

// Alpine Filters Data
window.filtersNoResultsTooltip = function () {
    return {
        ...tooltipBase(),
        isShowClick: false,
        showTp: false,
        html: 'a',
        checkHide() {
            if (this.isShowClick)
                this.isShowClick = false;
            else
                this.showTp = false;
        },
        filterNoResults($event) {
            const box = $event.detail.box;
            let top = box.top;
            top += window.pageYOffset | document.body.scrollTop;
            const tooltip = document.getElementById("filters-no-results-tooltip");
            tooltip.style.top = top + 'px';
            tooltip.style.left = (box.left + 180) + 'px';

            this.html = $event.detail.html;
            document.querySelector("#filters-no-results-tooltip .tooltip-body").innerHTML = this.html;

            this.isShowClick = true;
            this.showTp = true;
        },
    };
}

window.floatingCart = function (orderMinimumEnabled, orderMinimum, freeShippingThreshold) {
    return {
        ...window.cartBottomMessage(orderMinimumEnabled, orderMinimum, freeShippingThreshold),
        open: false,
        empty: true,
        products: [],
        total: {productsWoVat: 0},
        productRemoved: false,
        animation: {
            ['x-transition:enter']: 'ease-out duration-300',
            ['x-transition:enter-start']: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
            ['x-transition:enter-end']: 'opacity-100 translate-y-0 sm:scale-100',
            ['x-transition:leave']: 'ease-in duration-200',
            ['x-transition:leave-start']: 'opacity-100 translate-y-0 sm:scale-100',
            ['x-transition:leave-end']: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
        },
        updateFloatingCart({cart, products, eventProduct, actualQuantity}, $dispatch) {
            this.empty = cart.empty;
            this.products = cart.products.map(p => {
                return {
                    ...p, ...products[p.productCode],
                    hasDiscount: p.discountPct > 0,
                    discountPct: p.discountPct.toFixed(1)
                }
            }).sort((a, b) => {
                if (a.productCode === eventProduct)
                    return -1;
                else if (b.productCode === eventProduct)
                    return 1;
                else
                    return a.rowIndex - b.rowIndex;
            });

            this.productRemoved = actualQuantity <= 0;
            this.total.productsWoVat = utils.formatPrice(cart.total.productsWoVat);
            this.processBottomMessage(cart.total.productsWoVat, cart.flowType);

            if (this.empty) {
                $dispatch('show-floating-cart-empty');
                this.open = false;
            } else {
                this.open = true;
            }

            clearTimeout(this._resetProductsTimeout);
            this._animationIndex = 0;
        },
        closeFloatingCart($event) {
            if (this.isClickOnAlternativeArea($event))
                return;

            this.open = false;
            const self = this;
            this._resetProductsTimeout = setTimeout(() => {
                self.products = [];
            }, 300);
        },
        isClickOnAlternativeArea($event) {
            if ($event && $event.path) {
                for (let i = 0; i < $event.path.length; i++) {
                    let className = $event.path[i].className;
                    if (className && className.includes('floating-cart-alt-area'))
                        return true;
                }
            }

            return false;
        },


        _resetProductsTimeout: null,
        _animationIndex: 0,
        firstRowAnimation: {
            ['x-transition:enter']: 'transition ease-out delay-200 duration-500',
            ['x-transition:enter-start']: "opacity-0 transform scale-50",
            ['x-transition:enter-end']: "opacity-100 transform scale-100",
        },
        animationIfFirst() {
            const index = this._animationIndex;
            this._animationIndex++;
            return index === 0 ? this.firstRowAnimation : {};
        },


        imageSrc(product) {
            return utils.productImageSrc({
                productCode: product.productCode,
                imageCount: product.image_count
            }, 'thumb-sq');
        },
    };
}

window.footerMobileMenu = function () {
    return {
        open: false,
        ariaExp: 'false',
        click() {
            this.open = !this.open;
            this.ariaExp = this.open.toString();
        }
    };
}

window.inputs = {
    select: function () {
        return {
            value: null,
            setValue: function (value) {
                this.value = value;
            }
        }
    }
}


window.userLogin = function () {
    return {
        userCode: '',
        password: '',

        doLogin($dispatch) {
            const self = this;

            fetchForm('/login', {
                azione: "login",
                xhr: '1',
                username: this.userCode,
                password: this.password
            })
                .then(res => res.json())
                .then(res => {
                    console.log(res);
                    if (!res.success) {
                        modal.openDefinedModal($dispatch, 'login-auth-wrong');
                        return;
                    }

                    if (res.cartWillBeModified) {
                        modal.openDefinedModal($dispatch, 'login-alert-cart-merge')
                            .then(() => self.doRealLogin($dispatch));
                    } else {
                        self.doRealLogin($dispatch);
                    }
                });
        },
        doRealLogin($dispatch) {
            fetchForm('/login', {
                azione: "login",
                username: this.userCode,
                password: this.password
            })
                .then(() => {
                    document.location.reload();
                });
        },
        openPasswordRecovery($dispatch) {
            modal.openModal($dispatch, 'password-recovery');
        }
    };
}


window.socialLogin = {
    callback() {
        document.location.reload();
    },

    facebook: function (loginUrl, isDev) {
        return {
            runLogin($dispatch) {
                const width = 450;
                const height = 300;
                const left = (screen.width / 2) - (width / 2);
                const top = (screen.height / 10);
                window.open(loginUrl, '', 'width=' + width + ',height=' + height + ',top=' + top + ',left=' + left);
            }
        }
    }
}

window.carousel = function () {
    return {
        active: 0,
        slider: null,
        init() {
            let options = {
                slidesPerView: "auto",
                slidesPerGroupAuto: true,
                spaceBetween: 16,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                centerInsufficientSlides: true,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 10,
                    loadOnTransitionStart: true,
                },
            };
            console.log("init slider")
            this.slider = new Swiper(this.$refs.carousel, options);
            // this.slider.on('slideChange', i => this.active = i.activeIndex);
            // this.slider.on('slideChange', function (i) {
            //     console.log('slide changed', i);
            // });

        },
        next() {
            this.slider.slideNext();
        },
        prev() {
            this.slider.slidePrev();
        }
    }
}

window.couponBanner = function ({showCtaBanner, ctaBannerMessage}) {
    return {
        showBanner: showCtaBanner,
        message: ctaBannerMessage,

        update({show, message}) {
            this.showBanner = show;
            this.message = message;
        }
    };
}
window.anchorBanner = function () {
    return {
        open: true,
        cookieName: 'promo-banner',
        setCookieBanner(days = 1) {
            this.open = !this.open;
            Cookies.set(this.cookieName, this.open, {expires: days})
        },
        checkCookieBannerOpen() {
            let cookie = true;
            if (typeof Cookies.get(this.cookieName) !== "undefined")
                cookie = Cookies.get(this.cookieName) === 'true';
            // if (window.innerWidth > 1023) {
            //     cookie = true;
            // }
            this.open = cookie;
        },
        interval: null,
        checkCount: 0,
        init() {
            const self = this;
            this.checkCookieBannerOpen();
        }
    };
}

window.commonsForm = function () {
    return {
        toUpperCaseFiscalCode($event, modelName) {
            const value = $event.target.value.toUpperCaseFiscalCode();
            if (value === this.v[modelName])
                return;

            this.v[modelName] = value;
            $event.target.value = value;
            console.log($event.target.value, this.v[modelName]);
        },
        toUpperCaseBillingData($event, modelName) {
            const value = $event.target.value.toUpperCaseBillingData();
            if (value === this.v[modelName])
                return;

            this.v[modelName] = value;
            $event.target.value = value;
            console.log($event.target.value, this.v[modelName]);
        }
    };
}
