window.gtmUtils = {
    currentProduct: null,

    productImpression(product) {
        dataLayer.push({ecommerce: {detail: {products: [product]}}});
    },
    productsImpression(products) {
        dataLayer.push({
            currencyCode: 'EUR',
            impressions: products
        });
    },

    toggleFav(productCode, isFav) {
        let eventLabel = productCode;
        if (gtmUtils.currentProduct)
            eventLabel = gtmUtils.currentProduct.name;
        else {
            const preview = document.querySelector(".prod-item-preview.c" + productCode + " .preview-text");
            if (preview)
                eventLabel = preview.innerHTML;
        }

        if (isFav) {
            dataLayer.push({
                event: 'productWishlist',
                eventCategory: 'Ecommerce',
                eventAction: 'Aggiungi ai preferiti',
                eventLabel: eventLabel
            });
        } else {
            dataLayer.push({
                event: 'productWishlist',
                eventCategory: 'Ecommerce',
                eventAction: 'Rimuovi dai preferiti',
                eventLabel: eventLabel
            });
        }
    },

    cartProductEvent(product, quantityUpdate) {
        if (!product)
            return;
        if (quantityUpdate === 0)
            return;

        const products = [{
            ...product,
            quantity: Math.abs(quantityUpdate)
        }];
        const eventData =
            quantityUpdate > 0 ?
                {currencyCode: 'EUR', add: {products}} :
                {remove: {products}};

        dataLayer.push({
            event: quantityUpdate > 0 ? 'addToCart' : 'removeFromCart',
            ecommerce: eventData
        });
    },

    attivaLiveChat() {
        dataLayer.push({
            'event': 'attivaLiveChat',
            'eventCategory': 'Interazione',
            'eventAction': 'Attiva live chat'
        });
    },

    checkoutStep(step, products) {
        dataLayer.push({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: {step: step},
                    products: products
                }
            }
        });
    },
    checkoutOption: function (step, checkoutOption) {
        window.dataLayer.push({
            event: 'checkoutOption',
            ecommerce: {
                checkout_option: {
                    actionField: {step: step, option: checkoutOption}
                }
            }
        });
    },
};
