import 'alpinejs';

window.checkout22 = {};

window.checkout22.commons = function () {
    return {
        ...window.commonsForm(),

        openPrivacy($dispatch) {
            modal.openModal($dispatch, 'privacy');
        },
        openPasswordRecovery($dispatch) {
            modal.openModal($dispatch, 'password-recovery');
        },
        scrollToFirstError() {
            const errorField = utils.querySelectorAll(".field-error")
                .filter(e => !e.classList.contains("hidden"));
            if (errorField.length > 0) {
                const target = errorField[0].closest(".input-container");
                const offset = 300;
                console.log(target, offset);
                this.$scroll(target, {offset})
            }
        }
    };
}

window.checkout22.sidebar = function (signup) {

};

window.checkout22.billingForm = function (signup) {
    return {
        ...window.checkout22.commons(),

        signup: signup,
        v: {},
        billingRefs: [], otherRefs: [],

        initBillingForm($watch) {
            const self = this;
            this.scrollToFirstError();

            Object.keys(signup.billingForm).forEach(name => {
                const field = signup.billingForm[name];
                const fieldName = field.fieldName;
                self.v[fieldName] = field.filled ? field.userValue : '';
                self.billingRefs.push(self.$refs[fieldName]);
            });

            this.otherRefs = [this.$refs.newsletter, this.$refs.privacy];
            this.v.privacy = signup.privacy.filled;
            this.v.newsletter = signup.newsletter.filled;

            if (this.$refs.agent_key) {
                utils.show(this.$refs.agent_key);
                this.v.agent_key = signup.agentKey.userValue;
            }

            $watch('v.user_type', () => self.setUserType(self.v.user_type));
            this.setUserType(signup.billingForm.userType.userValue);
        },

        // User Type
        // ------------------------------------
        setUserType(value) {
            utils.show(this.billingRefs);
            utils.show(this.otherRefs);
            if (value === '0')
                this.setUserTypePrivate();
            else if (value === '1')
                this.setUserTypeBusiness();
            else if (value === '2')
                this.setUserTypeNonProfit();
            else {
                utils.hide(this.billingRefs);
                utils.hide(this.otherRefs);
            }
            utils.show(this.$refs.user_type);
        },
        setUserTypePrivate() {
            utils.hide([this.$refs.billing_business_name, this.$refs.vat_number,
                this.$refs.vat_number_allow_invalid, this.$refs.billing_certified_email, this.$refs.billing_sdi_code]);
            utils.show([this.$refs.fiscal_code]);
            utils.hide(this.$refs.billing_address_hint);
        },
        setUserTypeBusiness() {
            if (signup.billingForm.vatNumber.valid)
                utils.hide(this.$refs.vat_number_allow_invalid);
            else
                utils.show(this.$refs.vat_number_allow_invalid);
            utils.show([
                this.$refs.billing_business_name,
                this.$refs.vat_number, this.$refs.billing_certified_email, this.$refs.billing_sdi_code,
                this.$refs.billing_address_hint
            ]);
            utils.hide([this.$refs.fiscal_code]);
            utils.show(this.$refs.vat_number.querySelector('span.text-Main-Accent'));
        },
        setUserTypeNonProfit() {
            if (signup.billingForm.vatNumber.valid)
                utils.hide(this.$refs.vat_number_allow_invalid);
            else
                utils.show(this.$refs.vat_number_allow_invalid);
            utils.show([
                this.$refs.fiscal_code,
                this.$refs.billing_business_name, this.$refs.vat_number,
                this.$refs.billing_certified_email, this.$refs.billing_sdi_code,
                this.$refs.billing_address_hint
            ]);
            utils.hide(this.$refs.vat_number.querySelector('span.text-Main-Accent'));
        },
    }
}

window.checkout22.shippingForm = function (formData) {
    window.checkout22.palletOrderProvinceCode = formData.palletOrderProvinceCode || null;
    return {
        ...window.checkout22.commons(),

        v: {
            newsletter: formData.newsletter.filled,
            holiday_break: formData.holidayBreak && formData.holidayBreak.userValue,
            order_notes: formData.orderNotes.userValue,
            carrier_warning: formData.carrierWarning.filled,
        },

        initShippingForm($watch) {
            this.scrollToFirstError();
        },

        openCreateAddress($dispatch) {
            modal.openModal($dispatch, 'shipping-address', {createNew: true});
        }
    };
}

window.checkout22.shippingAddress = function (entityJson, $parent) {
    return {
        entity: entityJson ? JSON.parse(entityJson) : null,

        checkAkCarrierPreference($dispatch) {
            modal.openModal($dispatch, 'shipping-carrier-preference', this.entity);
        },

        editAddress($dispatch) {
            modal.openModal($dispatch, 'shipping-address', this.entity);
        },
        askDeleteAddress($dispatch) {
            const self = this;
            modal.openDefinedModal($dispatch, 'shipping-delete-address', this.entity)
                .then(detail => {
                    if (detail.action)
                        self.doDeleteAddress($dispatch);
                });
        },

        doDeleteAddress() {
            fetchPost('/api21/shipping-address/delete-address', {
                id: this.entity.id
            })
                .then(async res => {
                    if (!res.ok)
                        throw new Error(await res.text());
                    return res.json();
                })
                .then(res => {
                    if (res.success)
                        return document.location.reload();
                })
                .catch(utils.errorCatch);
        }
    };
}

window.checkout22.paymentMethod = function (orderDeprecatedId) {
    return {
        initPaymentMethod() {
            window.onpopstate = function (event) {
                document.location = '/view_storico_ordini_dettaglio.jsp?idOrdine=' + orderDeprecatedId;
                return false;
            };
            window.addEventListener('popstate', (event) => {
                document.location = '/view_storico_ordini_dettaglio.jsp?idOrdine=' + orderDeprecatedId;
                return false;
            });
            history.pushState({foo: "bar"}, document.title);
        }
    }
}

window.checkout22.googleMaps = {};
window.checkout22.googleMaps.init = function (mapElement, autocompleteElement, onPlaceChange) {
    if (mapElement.googleMap)
        return mapElement.googleMap;

    const CONFIGURATION = {
        "ctaTitle": "Checkout",
        "mapOptions": {
            "fullscreenControl": false,
            "mapTypeControl": false,
            "streetViewControl": false,
            "zoom": 13,
            "zoomControl": true,
            "maxZoom": 0
        },
        "mapsApiKey": "AIzaSyA31O5BnOqV5-tXd8jp76yCAzIA7h-bE3A",
        "capabilities": {"addressAutocompleteControl": true, "mapDisplayControl": true, "ctaControl": true}
    };

    const map = new google.maps.Map(mapElement, {
        zoom: CONFIGURATION.mapOptions.zoom,
        center: {lat: 45.439205, lng: 9.153491},
        mapTypeControl: CONFIGURATION.mapOptions.mapTypeControl,
        fullscreenControl: CONFIGURATION.mapOptions.fullscreenControl,
        zoomControl: CONFIGURATION.mapOptions.zoomControl,
        streetViewControl: CONFIGURATION.mapOptions.streetViewControl
    });
    const marker = new google.maps.Marker({map: map, draggable: false});
    const autocomplete = new google.maps.places.Autocomplete(autocompleteElement, {
        fields: ["address_components", "geometry", 'place_id'], // , "name"
        types: ["address"],
    });
    autocomplete.addListener('place_changed', function () {
        marker.setVisible(false);
        const place = autocomplete.getPlace();
        if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert('Non ci sono informazioni per il luogo indicato: \'' + place.name + '\'');
            return;
        }
        renderAddress(place);

        onPlaceChange({
            ...place,
            getComponent(name, type) {
                type || (type = 'long');
                for (const component of place.address_components) {
                    if (component.types[0] === name)
                        return component[type + '_name'];
                }
            }
        });
    });

    function renderAddress(place) {
        map.setCenter(place.geometry.location);
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
    }

    mapElement.googleMap = {
        map, marker,
        setCenter(location) {
            map.setCenter(location);
            marker.setPosition(location);
        }
    };
    return mapElement.googleMap;
}
window.onGoogleMapReady = function () {
    console.log('Google Map ready');
}


window.modal.sippingAddressSubmit = function () {
    return {
        ...window.modal.single('shipping-address'),

        toUpperCaseShippingData($event, modelName) {
            const value = $event.target.value.toUpperCaseBillingData();
            if (value === this.v[modelName])
                return;

            this.v[modelName] = value;
            $event.target.value = value;
            console.log($event.target.value, this.v[modelName]);
        },

        modalTitle: null,
        v: {
            street_number: true,
        },
        // Causa autofill chrome
        isUsingAssistant: false,
        address_wo_house_number_error: false,

        openShippingAddress(modalData, $watch) {
            this.initData(modalData, $watch);

            this.open(modalData);

            this.initGoogleMaps();
        },
        initData(modalData, $watch) {
            if (modalData.createNew) {
                this.modalTitle = 'Nuovo indirizzo';
                this.v = {
                    enable_address_assistant: true,
                    address_wo_house_number: false
                };
            } else {
                this.modalTitle = 'Modifica indirizzo';
                this.v.receiver_business = modalData.receiverBusiness;
                this.v.receiver_name = modalData.receiverName;
                this.v.receiver_surname = modalData.receiverSurname;
                this.v.enable_address_assistant = modalData.assistantEnabled;
                this.v.shipping_address_street = modalData.addrStreet;
                this.v.maps_shipping_address_street = modalData.addrStreet;
                this.v.address_wo_house_number = modalData.addressWoHouseNumber;
                this.v.shipping_address_city = modalData.addrCity;
                this.v.shipping_address_zip = modalData.addrPostalCode;
                this.v.shipping_address_province = modalData.addrProvince;
                this.v.shipping_telephone_number = modalData.addrPhoneNumber;
                this.v.shipping_address_notes = modalData.addrNotes;
                this.v.place_id = modalData.assistantPlaceId;
            }

            utils.hide(this.$refs.address_wo_house_number)
            const self = this;
            self.toggleAddressStreet();
            $watch('v.enable_address_assistant', () => self.toggleAddressStreet());
        },

        initGoogleMaps() {
            const self = this;
            this.googleMap = window.checkout22.googleMaps.init(
                this.$refs.googleMapsTarget,
                this.$refs.maps_shipping_address_street_input,
                (place) => self.updateAddressFromMapPlace(place)
            );
            if (this.modalData.geoLatitude && this.modalData.geoLongitude)
                this.googleMap.setCenter({
                    lat: this.modalData.geoLatitude,
                    lng: this.modalData.geoLongitude
                })
        },
        toggleAddressStreet() {
            this.isUsingAssistant = false;
            if (this.v.enable_address_assistant) {
                utils.show(this.$refs.maps_shipping_address_street);
                utils.hide(this.$refs.shipping_address_street);
                if (this.address_wo_house_number_error)
                    utils.show(this.$refs.address_wo_house_number);

                utils.addClass(this.$refs.form_data_col, 'sm:w-1/2');
                utils.addClass(this.$refs.form_maps_col, 'sm:block');
            } else {
                utils.show(this.$refs.shipping_address_street);
                utils.hide(this.$refs.maps_shipping_address_street);
                utils.hide(this.$refs.address_wo_house_number)

                utils.removeClass(this.$refs.form_data_col, 'sm:w-1/2');
                utils.removeClass(this.$refs.form_maps_col, 'sm:block');
            }
        },
        updateAddressFromMapPlace(place) {
            this.isUsingAssistant = true;
            this.v.address_wo_house_number = false;

            this.v.street_number = place.getComponent('street_number');
            if (this.v.street_number === undefined) {
                this.address_wo_house_number_error = true;
                this.v.street_number = ''
                this.setError('maps_shipping_address_street', `Inserire il numero civico`);
                utils.show(this.$refs.address_wo_house_number)
            } else {
                this.address_wo_house_number_error = false;
                this.unsetError('maps_shipping_address_street');
                utils.hide(this.$refs.address_wo_house_number)
            }

            const province = place.getComponent('administrative_area_level_2', 'short');
            if (window.checkout22.palletOrderProvinceCode && province !== window.checkout22.palletOrderProvinceCode)
                this.setError('shipping_address_province', "Provincia non ammessa");
            else
                this.unsetError('shipping_address_province');

            this.v.latitude = place.geometry.location.lat();
            this.v.longitude = place.geometry.location.lng();

            let streetAddress = place.getComponent('route') + ' ' + this.v.street_number;
            this.v.shipping_address_street = streetAddress.toUpperCaseBillingData();
            this.v.maps_shipping_address_street = streetAddress;

            this.v.shipping_address_city = place.getComponent('locality').toUpperCaseBillingData();
            this.v.shipping_address_zip = place.getComponent('postal_code');
            this.v.shipping_address_province = province;
            this.v.place_id = place.place_id;
        },

        submitAddress($dispatch) {
            if (!this.opened)
                return;

            // Causa autofill chrome
            if (!this.v.shipping_address_street && this.v.maps_shipping_address_street)
                this.v.shipping_address_street = this.v.maps_shipping_address_street;

            if (this.isUsingAssistant && !this.v.street_number && !this.v.address_wo_house_number) {
                alert('Inserire il numero civico');
                return;
            }

            const self = this;
            const data = {
                shipping_address_id: this.modalData.id || -1,
                ...this.v
            };
            console.log(data, this.modalData.id);
            fetchPost('/api21/shipping-address/submit-address', data)
                .then(async res => {
                    if (!res.ok)
                        throw new Error(await res.text());
                    return res.json();
                })
                .then(res => {
                    console.log(res);
                    if (res.success)
                        return document.location.reload();

                    self.unsetErrorsAll();
                    res.errors.forEach(err => self.setError(err.inputName, err.errorMessage));
                })
                .catch(utils.errorCatch);
        },

        unsetErrorsAll() {
            utils.querySelectorAll("#shipping-address-modal-form .input-container")
                .forEach(container => {
                    utils.removeClass(container, 'w-error');
                    utils.addClass(container.querySelector('.field-error'), 'hidden');
                });
        },
        unsetError(inputName) {
            const container = document.querySelector("#shipping-address-modal-form .input-container[x-ref=" + inputName + "]");
            utils.removeClass(container, 'w-error');
            utils.addClass(container.querySelector('.field-error'), 'hidden');
        },
        setError(inputName, errorMessage) {
            const selector = "#shipping-address-modal-form .input-container[x-ref=" + inputName + "]";
            const container = document.querySelector(selector);
            utils.addClass(container, 'w-error');
            const error = container.querySelector('.field-error');
            utils.removeClass(error, 'hidden');
            error.innerHTML = errorMessage;
        },
        animation: this.animation
    };
}
