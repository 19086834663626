import 'alpinejs';
import Cookies from "js-cookie";

window.signin = function () {
    return {
        loginCookieName: "login-open",
        signupCookieName: "signup-open",
        accediOpen: false,
        registratiOpen: false,
        initSignin() {
            const gsiClient = document.createElement('script');
            gsiClient.setAttribute('src','https://accounts.google.com/gsi/client');
            gsiClient.setAttribute('async','');
            gsiClient.setAttribute('defer','');
            document.body.appendChild(gsiClient);

            if (Cookies.get(this.loginCookieName) === "true") {
                console.log(Cookies.get(this.loginCookieName))
                this.openAccedi();
            }
            if (Cookies.get(this.signupCookieName) === "true") {
                console.log(Cookies.get(this.signupCookieName))
                this.openRegistrati();
            }
        },
        accediToggle($event) {
            if ($event.target.closest('label') && this.accediOpen) {
                this.closeAccedi();
            } else {
                this.openAccedi();
                this.closeRegistrati();
            }
        },
        registratiToggle($event) {
            if ($event.target.closest('label') && this.registratiOpen) {
                this.closeRegistrati();
            } else {
                this.openRegistrati();
                this.closeAccedi();
            }
        },
        openAccedi() {
            this.accediOpen = true;
            Cookies.set(this.loginCookieName, this.accediOpen);
            utils.removeClass(document.getElementById("login-form"), 'hidden');
        },
        closeAccedi() {
            this.accediOpen = false;
            Cookies.set(this.loginCookieName, this.accediOpen);
            utils.addClass(document.getElementById("login-form"), 'hidden');
        },
        openRegistrati() {
            this.registratiOpen = true;
            Cookies.set(this.signupCookieName, this.registratiOpen);
            utils.removeClass(document.getElementById("billing-form"), 'hidden');
        },
        closeRegistrati() {
            this.registratiOpen = false;
            Cookies.set(this.signupCookieName, this.registratiOpen);
            utils.addClass(document.getElementById("billing-form"), 'hidden');
        },
        openPasswordRecovery($dispatch) {
            modal.openModal($dispatch, 'password-recovery');
        }
    };
}
