window.GoogleSignIn = {
    timeout: 250,
    _intervalInstance: null,

    handleCredentialResponse(response) {
        fetchPost('/api21/login/google', {
            google_token: response.credential
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if (res && res.redirect) {
                console.log(res.redirect)
                window.open(res.redirect, '', 'width=800,height=600,left=50,top=50');
            } else {
                window.location.reload()
            }
        })
        .catch(function () {
            alert("Errore interno...");
        });
    },
    init() {
        GoogleSignIn._intervalInstance = setInterval(GoogleSignIn.initGoogle, GoogleSignIn.timeout);
    },
    initGoogle() {
        try {
            if (typeof google.accounts.id === 'undefined')
                return;
        } catch (e) {
            return;
        }

        window.clearInterval(GoogleSignIn._intervalInstance);

        google.accounts.id.initialize({
            client_id: "306560371653-8pqsn4fl9dr2go35seudav83ga8t2se9.apps.googleusercontent.com",
            callback: GoogleSignIn.handleCredentialResponse
        });
        google.accounts.id.renderButton(
            document.getElementById("googleSingIn"),
            {theme: "outline", size: "large"}  // customization attributes
        );
        // google.accounts.id.prompt(); // also display the One Tap dialog
    }
};
document.addEventListener("DOMContentLoaded", GoogleSignIn.init);
