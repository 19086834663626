import 'alpinejs';
import Swiper from 'swiper/bundle';

window.Swiper = Swiper;

window.productQuantityDiscount = function (quantityFrom, quantityEnd) {
    return {
        quantityFrom,
        quantityEnd,
        highlight: false,
        initQuantityDiscount(actualQuantity) {
            this.updateQuantityHighlight(actualQuantity);
        },
        updateQuantityHighlight(actualQuantity) {
            this.highlight = false
            if (this.quantityEnd === -1) {
                if (actualQuantity >= this.quantityFrom) {
                    this.highlight = true
                }
            } else {
                if (actualQuantity >= this.quantityFrom && actualQuantity <= this.quantityEnd) {
                    this.highlight = true
                }
            }
        }
    }
}

window.imageGallery = function (isFav) {
    const images = Array.from(document.querySelectorAll(".product-gallery.prod-gallery-item"));
    const isImg = images.map(() => false);
    isImg[0] = true;
    return {
        isFav,
        imageModalOpen: false,
        isImg: Object.assign({}, isImg),
        activeImg: 0,
        productImageSlider: null,
        modalActiveImg: 0,
        modalIsImg: Object.assign({}, isImg),
        productImageModalSlider: null,
        openModal() {
            this.imageModalOpen = true;
        },
        closeImageModal() {
            this.imageModalOpen = false;
        },
        zoom(e) {
            const zoomer = e.currentTarget;
            let offsetX
            let offsetY;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            const x = offsetX / zoomer.offsetWidth * 100
            console.log(offsetX, offsetY, x, offsetY / zoomer.offsetHeight * 100
            )
            const y = offsetY / zoomer.offsetHeight * 100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        },
        setImgOutside(index) {
            this.productImageSlider.slideTo(index);
        },
        setProductImg(index) {
            this.productImageSlider.slideTo(index);
            this.isImg[this.activeImg] = false;
            this.activeImg = index;
            this.isImg[index] = true;
        },
        setModalGalleryImg(index) {
            this.productImageModalSlider.slideTo(index);
            this.modalIsImg[this.modalActiveImg] = false;
            this.modalActiveImg = index;
            this.modalIsImg[index] = true;
        },
        initGallery() {
            let options = {
                slidesPerView: 1,
                navigation: false,
            }

            this.productImageSlider = new Swiper(this.$refs.fullGallery, options);
            this.productImageSlider.on('slideChange', i => this.setProductImg(i.activeIndex));

            options = {
                slidesPerView: 1,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            }

            this.productImageModalSlider = new Swiper(this.$refs.productImageModal, options);
            this.productImageModalSlider.on('slideChange', i => this.setModalGalleryImg(i.activeIndex));
        },
    }
}

window.productDetail = function (productCode, packageSize, isFav, finalPrice, categoriaRaccoglitore) {
    return {
        productCode,
        isFav,
        packageSize,
        showScaglioniTp: false,
        smBreakpoint: 1024,
        lgBreakpoint: 1500,
        // ---- CUSTOM PER MODALE RACCOGLITORI
        categoriaRaccoglitore,
        // ---- CUSTOM PER MODALE RACCOGLITORI
        ...window.productCommon(),
        // ---------------------
        initProductDetail(cartQuantity, cartConfig, $watch) {
            console.log("init detail")
            if (cartQuantity > 0) {
                this.$refs.inputCartQuantity.style.color = "#ce3575";
            }

            this.cartQuantity = cartQuantity;
            this.buyMultiplier = cartConfig.buyMultiplier || 1;
            this.cartUpdateQuantity = cartQuantity > 0 ? cartQuantity : (cartConfig.buyMultiplier > 1 ? cartConfig.buyMultiplier : 1);
            this.cartConfig = cartConfig;
            this.selectorWheel = cartConfig.selectorWheel;

            const h1 = document.querySelector("div.prod-detail h1").innerHTML;
            const brand = document.querySelector("div.prod-detail img.brand-image").alt;
            const category = document.querySelector(".breadcrumb .breadcrumb-link:nth-last-child(2) a p").innerHTML;
            gtmUtils.currentProduct = {name: h1, id: productCode, price: finalPrice, brand, category};
            gtmUtils.productImpression(gtmUtils.currentProduct);
            const self = this;
            $watch('cartUpdateQuantity', value => {
                // console.log('EVENT cartUpdateQuantity', this.cartUpdateQuantity, value);
                if (utils.isArray(value))
                    value = value[0];

                if (parseInt(value) === -100 && self.$refs.inputCartQuantity.dataset.palletUrl) {
                    document.location = self.$refs.inputCartQuantity.dataset.palletUrl;
                    return;
                }

                if (!/^\d+$/.test(value))
                    return;

                value = parseInt(value);
                if (value % self.buyMultiplier !== 0)
                    value = Math.ceil(value / self.buyMultiplier) * self.buyMultiplier;

                if (this.cartUpdateQuantity !== value) {
                    this.cartUpdateQuantity = value;
                    // $watch event is going to be fired again
                }
            });
            this.resize();
            if (this.selectorWheel)
                this.initSelectorWheel();
        },

        // ---------------------
        cartConfig: {deniedByModal: null, modalChain: [], modalData: {}},
        cartQuantity: 0,
        buyMultiplier: 1,
        cartUpdateQuantity: 0,
        addToCart($dispatch) {
            if (this.cartConfig.deniedByModal) {
                return window.modal.openModal($dispatch, this.cartConfig.deniedByModal, {
                    ...this.cartConfig.modalData, productCode
                });
            }

            if (this.cartQuantity > 0 && this.cartUpdateQuantity <= 0) {
                modal.openDefinedModal($dispatch, 'cart-confirm-remove').then(detail => {
                    if (detail.action === true)
                        CartApi.removeProductFromCart(productCode, $dispatch);
                });
            } else {
                if (this.cartUpdateQuantity <= 0)
                    this.cartUpdateQuantity = 1;
                if (this.cartUpdateQuantity === this.cartQuantity && !this.cartConfig.buyQuantitySelectable)
                    this.cartUpdateQuantity++;
                this.cartChain($dispatch);
            }
            $dispatch('update-quantityhighlight-view', this.cartUpdateQuantity);
        },
        async cartChain($dispatch) {
            const modalChain = this.cartConfig.modalChain.slice();
            console.log(this.categoriaRaccoglitore, this.packageSize, this.cartUpdateQuantity, this.cartQuantity);
            if (this.categoriaRaccoglitore && this.packageSize > 1 && this.cartUpdateQuantity > 1) {
                modalChain.unshift('raccoglitore-confezione-maggiore-uno');
                this.cartConfig.modalData.packageSize = this.packageSize;
                this.cartConfig.modalData.cartUpdateQuantity = this.cartUpdateQuantity;
                this.cartConfig.modalData.totalQuantity = this.packageSize * this.cartUpdateQuantity;
            }
            while (modalChain.length > 0) {
                const nextModal = modalChain.shift();
                const res = await modal.openDefinedModal($dispatch, nextModal, this.cartConfig.modalData);
                if (res.action !== true)
                    return;
            }
            CartApi.updateProductIntoCart(productCode, this.cartUpdateQuantity / this.buyMultiplier, $dispatch);
        },

        updateDetailCartData({actualQuantity, quantityVariation}) {
            gtmUtils.cartProductEvent(gtmUtils.currentProduct, quantityVariation);

            this.cartQuantity = actualQuantity * this.buyMultiplier;
            this.cartUpdateQuantity = actualQuantity * this.buyMultiplier;

            this.$refs.inputCartQuantity.style.color = actualQuantity > 0 ? "#ce3575" : "#2a455c";
        },

        // ---------------------
        selectorWheel: false,
        $wheel: null, $fakeKey: null,
        $options: [],
        wheelScrollData: {selectedIndex: -1, optionHeight: 20, paddingOffset: 14, targetScroll: 0},
        initSelectorWheel() {
            this.$wheel = this.$refs.wheel;
            this.$fakeKey = this.$refs.inputCartQuantity; // si chiama uguale solo per compatibilità
            this.$options = [this.$refs.qty0, ...this.cartConfig.buyQuantityOptions.map((x, i) => this.$refs['qty' + x])];
            this.optionClick(this.cartUpdateQuantity);

            const self = this;
            utils.scroll.intercept(this.$wheel, (e) => {
                if (e.isScrollDown !== undefined)
                    self.scrollOptBy(e.isScrollDown ? 1 : -1);
                else if (e.dragStart) {
                    self.wheelScrollData.scrollStartIndex = self.wheelScrollData.selectedIndex;
                } else if (e.dragY !== undefined && Math.abs(e.dragY) > this.wheelScrollData.optionHeight) {
                    const scrollCount = Math.round(e.dragY / this.wheelScrollData.optionHeight);
                    self.scrollToIndex(self.wheelScrollData.scrollStartIndex + scrollCount);
                }
            });
            this.$fakeKey.addEventListener('keydown', utils.scroll.interceptEventForScrollKeys(e => {
                self.scrollOptBy(e.isScrollDown ? 1 : -1);
            }));
        },
        scrollOptBy(delta) {
            this.scrollToIndex(this.wheelScrollData.selectedIndex + delta);
        },
        scrollToIndex(index) {
            this.$fakeKey.focus();
            index = Math.max(0, Math.min(this.$options.length - 1, index));

            const value = parseInt(this.$options[index].dataset.value);
            if (this.cartUpdateQuantity !== value) {
                // console.log(`UPDATE selectedIndex ${index} to value ${value}`);
                this.cartUpdateQuantity = value;
            }

            if (this.wheelScrollData.selectedIndex !== index) {
                this.wheelScrollData.selectedIndex = index;
                this.highlightQuantity(value);
            }

            const targetScroll = this.wheelOptionIndexToScroll(index);
            if (Math.abs(this.wheelScrollData.targetScroll - targetScroll) > 1) {
                this.wheelScrollData.targetScroll = targetScroll;
                // console.log(`UPDATE selectedIndex ${index} to targetScroll ${targetScroll}`);
                this.$refs.items.style.top = (-targetScroll) + "px";
            }
        },
        highlightQuantity(selectedValue) {
            this.$options.forEach($option => utils.removeClass($option, 'selected') || utils.addClass($option, 'unselected'));
            const $selectedOption = this.$refs['qty' + selectedValue];
            utils.removeClass($selectedOption, 'unselected');
            utils.addClass($selectedOption, 'selected');
        },
        optionClick(qty, $event) {
            $event && $event.preventDefault();
            this.scrollToIndex(this.wheelValueToIndex(qty));
            utils.resetTextSelection();
        },
        wheelOptionIndexToScroll(index) {
            (index !== undefined) || (index = this.wheelScrollData.selectedIndex);
            let scrollTo = index * this.wheelScrollData.optionHeight - this.wheelScrollData.paddingOffset;
            return Math.max(-this.wheelScrollData.paddingOffset, scrollTo);
        },
        wheelValueToIndex(value) {
            for (let i = 0; i < this.$options.length; i++) {
                const $option = this.$options[i];
                if (parseInt($option.dataset.value) === parseInt(value))
                    return i;
            }
            return -1;
        },

        // ---------------------
        goToPallet(url) {
            document.location = url;
        },
        infoProductButtonClicked() {
            let scrollToClass = ".prod-bottom-container .product-specs";

            if (window.innerWidth >= this.smBreakpoint) {
                scrollToClass = ".product-detail-container .product-specs";
            }

            if (window.innerWidth >= this.lgBreakpoint) {
                scrollToClass = ".prod-bottom-container .product-specs";
            }

            document.querySelector(scrollToClass).scrollIntoView({behavior: "smooth"});
        },
        resize() {
            if (window.innerWidth >= this.lgBreakpoint) {
                this.$refs.feedatyContainerLg.appendChild(document.querySelector('.feedaty-main'))

                if (document.querySelector('.availability-pieces') !== null) {
                    this.$refs.availabilityLg.appendChild(document.querySelector('.availability-pieces'))
                    this.$refs.availabilityLg.style.display = "block";
                }

                if (document.querySelector('.product-info-button') !== null)
                    this.$refs.productDescription.appendChild(document.querySelector('.product-info-button'))

                if (document.querySelector('.availability-delay-days') !== null) {
                    this.$refs.availabilityLg.appendChild(document.querySelector('.availability-delay-days'));
                    this.$refs.availabilityLg.style.display = "block";
                }

                if (document.querySelector('.availability-deferred') !== null) {
                    this.$refs.availabilityLg.appendChild(document.querySelector('.availability-deferred'));
                    this.$refs.availabilityLg.style.display = "block";
                }

                if (document.querySelector('.product-container').offsetHeight > document.querySelector('.central-col').offsetHeight)
                    document.querySelector('.central-col').style.height = document.querySelector('.product-container').offsetHeight + 'px';
            } else {
                document.querySelector('.central-col').style.height = 'initial';
                this.$refs.feedatyContainerSmall.appendChild(document.querySelector('.feedaty-main'))

                if (document.querySelector('.availability-pieces') !== null) {
                    this.$refs.availabilityLg.style.display = "none";
                    this.$refs.availabilityPieces.appendChild(document.querySelector('.availability-pieces'));
                }

                if (document.querySelector('.availability-delay-days') !== null) {
                    this.$refs.availabilityDelayDays.appendChild(document.querySelector('.availability-delay-days'));
                }

                if (document.querySelector('.availability-deferred') !== null) {
                    this.$refs.availabilityDeferred.appendChild(document.querySelector('.availability-deferred'));
                }

                if (document.querySelector('.product-info-button') !== null)
                    this.$refs.rightInfoCol.appendChild(document.querySelector('.product-info-button'))
            }
        },
    };
}
