import 'alpinejs';
import Cookies from "js-cookie";

window.searchBar = function () {
    return {
        ...tooltipBase(), // qui ci sono le animazioni

        // Tooltip
        // ------------------------------------
        showSearchTipBanner: false,
        cookieName: 'searchTipBanner',
        setCookie(days = 1) {
            Cookies.set(this.cookieName, !this.showSearchTipBanner, {expires: days})
            this.showSearchTipBanner = false;
        },
        checkCookie() {
            const cookie = Cookies.get(this.cookieName) || 'true';
            return this.showSearchTipBanner = cookie === 'true';
        },

        // Search Bar
        // ------------------------------------
        resetPlaceholder() {
            this.$refs.search.placeholder = '';
        },
        restorePlaceholder() {
            this.$refs.search.placeholder = 'Ricerca per: parole, frasi, codice prodotto, codice produttore';
        },
    }
}

window.navbar = function () {
    return {
        open: false,
        favCount: 0,
        hasFav: false,
        favHref: "/view_catalogo_personale.jsp",
        countFav($event) {
            this.favCount += $event.detail.count;
            this.hasFav = this.favCount > 0;
        },

        cart: {
            empty: true,
            rowsQuantitySum: 0,
            productsWoVat: ''
        },
        cartHref: "/carrello.jsp",
        initNavCart(productsWoVat, rowsQuantitySum, favCountStart) {
            this.cart.productsWoVat = productsWoVat;
            this.cart.rowsQuantitySum = rowsQuantitySum;
            this.cart.empty = rowsQuantitySum <= 0;
            this.cartHref = this.cart.empty ? 'javascript:void(0)' : "/carrello.jsp";

            this.favCount = favCountStart;
            this.hasFav = this.favCount > 0;
            this.favHref = this.hasFav ? "/view_catalogo_personale.jsp" : 'javascript:void(0)';
        },
        updateNavCartData({cart}) {
            this.cart.empty = cart.empty;
            this.cartHref = this.cart.empty ? 'javascript:void(0)' : "/carrello.jsp";
            if (cart.empty) {
                this.cart.rowsQuantitySum = 0;
                this.cart.productsWoVat = 0;
            } else {
                this.cart.rowsQuantitySum = cart.total.rowsQuantitySum;
                this.cart.productsWoVat = utils.formatPrice(cart.total.productsWoVat);
            }
        },
        navbarCartClick($dispatch) {
            if (this.cart.empty) {
                $dispatch('show-floating-cart-empty');
            } else {
                document.location = "/carrello.jsp";
            }
        },
        navbarFavClick($dispatch) {
            if (this.hasFav) {
                document.location = "/view_catalogo_personale.jsp";
            } else {
                $dispatch('show-floating-favorites-empty');
            }
        }
    }
}

// Alpine Upbar Data
window.upbarData = function () {
    return {
        tpEasyReturn: false,
        tpBuyNoLogin: false,
        tpFreeShipping: false,
        tpDeliveryTime: false,
        ...tooltipBase()
    }
}
