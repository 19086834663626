import 'alpinejs';

window.subcategorySlider = function () {
    return {
        active: 0,
        slider: null,
        sliderActive: false,
        initSlider() {
            let options = {
                slidesPerView: "auto",
                slidesPerGroupAuto: true,
                spaceBetween: 8,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                centerInsufficientSlides: true,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 10,
                    loadOnTransitionStart: true,
                },
            };
            this.slider = new Swiper(this.$refs.slider, options);

        },
        next() {
            this.slider.slideNext();
        },
        prev() {
            this.slider.slidePrev();
        },
        init() {
            this.resizeSwiper();
        },
        resizeSwiper() {
            if (window.innerWidth <= 1024) {
                this.checkSwiperActive();
            } else {
                this.destroySwiper()
            }
        },
        destroySwiper() {
            if (this.sliderActive) {
                this.slider.destroy();
                this.sliderActive = false;
            }
        },
        checkSwiperActive() {
            if (!this.sliderActive) {
                this.sliderActive = true;
                this.initSlider();
            }
        }
    }
}
