import 'alpinejs';
import Fuse from 'fuse.js'

window.referenceSearch = function () {
    return {
        initReferenceSearch() {
            // get-printers-by-query
        },
        query: "",
        inputValue: "",
        isSearchByReferenceOpen: false,
        fullData: [],
        suggestions: [],
        currentItems: 30,
        animation: {
            ['x-transition:enter']: 'transition ease-in duration-100',
            ['x-transition:enter-start']: 'opacity-0 transform scale-y-90',
            ['x-transition:enter-end']: 'opacity-100 transform scale-y-100',
            ['x-transition:leave']: 'transition ease-in duration-100',
            ['x-transition:leave-start']: 'opacity-100 transform scale-y-100',
            ['x-transition:leave-end']: 'opacity-0 transform scale-y-90',
        },
        updateSuggestions: function (query) {
            if (query.length <= 0) {
                this.suggestions = [];
                this.isSearchByReferenceOpen = false;
                return;
            }
            fetch('/api21/consumable/consumables?query=' + query.replaceAll(" ", "")).then(res => res.json()).then(res => {
                this.fullData = res.data;
                if (res.data.length > 30) {
                    this.suggestions = res.data.slice(0, 30);
                } else {
                    this.suggestions = res.data;
                }
                this.isSearchByReferenceOpen = true
            });
        },
        scrolling(event) {
            if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 100) {
                this.suggestions = this.fullData.slice(0, this.currentItems + 30);
                this.currentItems += 30;
            }
        },
        toggleSearchByReference() {
            this.isSearchByReferenceOpen = this.inputValue.length > 0;
        },
        closeSelect() {
            this.isSearchByReferenceOpen = false;
        }
    }
}

window.printerSearch = function () {
    return {
        fullModelData: [],
        brandId: "",
        brands: window.printerBrands,
        showLoader: false,
        showPrinterSelect: false,
        firstTimeMobile: false,
        currentItems: 30,
        async init($watch, $nextTick, searchData) {
            if (searchData != null) {
                const selectedBrand = this.brands.filter(el => el.brand_id === searchData.brandId);
                const brandItem = {
                    brand_name: selectedBrand[0].brand_name,
                    brand_id: selectedBrand[0].brand_id
                }

                this.selectBrand(brandItem, $nextTick, false);

                if (typeof searchData.uniqueKey !== "undefined" && searchData.uniqueKey !== null && searchData.uniqueKey !== "")
                    this.modelSelectionTitle = searchData.uniqueKey;
            }
            this.fuseBrand = new Fuse(window.printerBrands, {
                threshold: 0.4,
                minMatchCharLength: 0,
                keys: ["brand_name"]
            });

            // $watch('isSearchByModelOpen', isSearchByModelOpen => console.log(isSearchByModelOpen));
            $watch('suggestionsModel', suggestionsModel => {
                document.getElementById("printer-model").focus();
            });
        },
        toggleSearchByBrand() {
            if (!this.firstTimeMobile) {
                document.getElementsByClassName("printer-selects")[0].classList.remove("height-printer-select");
            }
            this.isSearchByBrandOpen = !this.isSearchByBrandOpen;
            this.isSearchByModelOpen = false;
            this.updateSuggestions("");
        },
        scrollingModel(event) {
            if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 100) {
                this.suggestionsModel = this.fullModelData.slice(0, this.currentItems + 30);
                this.currentItems += 30;
            }
        },
        async loadPrintersByBrand(query) {
            const self = this;
            this.showLoader = true;
            return fetch('/api21/consumable/printers?query=' + query + '&brandId=' + this.brandId)
                .then(res => res.json())
                .then(res => {
                    this.fullModelData = res.printers;
                    if (res.printers.length > 30) {
                        this.suggestionsModel = res.printers.slice(0, 30);
                    } else {
                        this.suggestionsModel = res.printers;
                    }
                    self.showPrinterSelect = true;
                    self.showLoader = false;
                })
                .catch(utils.errorLog);
        },
        animation: {
            ['x-transition:enter']: 'transition ease-in duration-50',
            ['x-transition:enter-start']: 'opacity-0 transform scale-y-90',
            ['x-transition:enter-end']: 'opacity-100 transform scale-y-100',
            ['x-transition:leave']: 'transition ease-in duration-100',
            ['x-transition:leave-start']: 'opacity-100 transform scale-y-100',
            ['x-transition:leave-end']: 'opacity-0 transform scale-y-90',
        },
        printerModelClick(printerKey) {
            if (printerKey)
                document.location = '/pr/' + printerKey;
        },
        fuseBrand: null,
        doFuseSearch(query) {
            return this.fuseBrand.search(query);
        },
        activeSuggestion: null,
        inputValueBrand: "",
        brandSelectionTitle: "Seleziona Marca",
        modelSelectionTitle: "Seleziona Modello",
        isSearchByBrandOpen: false,
        suggestions: [],
        query: "",
        updateSuggestions: function (data) {
            this.query = data;
            if (!this.query) {
                this.suggestions = window.printerBrands.map((item, refIndex) => ({
                    item,
                    refIndex,
                    matches: [],
                    score: 0,
                }));
            } else {
                this.suggestions = this.doFuseSearch(this.query);
            }
        },
        selectBrand(item, $nextTick, openModel = true) {
            const brandId = item.brand_id;
            this.brandId = brandId;
            this.brandSelectionTitle = item.brand_name;
            this.isSearchByBrandOpen = false;
            this.loadPrintersByBrand(this.inputValueModel, brandId).then(async () => {
                    this.isSearchByModelOpen = openModel;
                    this.updateSuggestionsModel("").then(() => {
                        $nextTick(() => {
                            this.$refs.printerModelInput.focus();
                        });
                    });
                }
            )
        },
        toggleSearchByModel($nextTick) {
            if (this.brandId === "") {
                this.toggleSearchByBrand($nextTick);
            } else {
                this.isSearchByModelOpen = !this.isSearchByModelOpen;
                this.updateSuggestionsModel("");
                $nextTick(() => {
                    this.$refs.printerModelInput.focus();
                });
            }
        },
        isSearchByModelOpen: false,
        inputValueModel: "",
        queryModel: "",
        fuseModel: null,
        suggestionsModel: [],
        async doFuseSearchModel(query) {
            return await this.fuseModel.search(query);
        },
        updateSuggestionsModel: async function (data) {
            this.queryModel = data;
            this.loadPrintersByBrand(data, this.brandId);

        },
        selectModel(item) {
            this.modelSelectionTitle = item;
            this.inputValueModel = item;
        },
        closeAllSelects() {
            this.isSearchByBrandOpen = false;
            this.isSearchByModelOpen = false;
        }
    };
}
