import 'alpinejs';

window.homeSlider = function () {
    return {
        active: 0,
        slider: null,
        init($watch) {
            let options = {
                slidesPerView: 1,
                spaceBetween: 0,
                autoHeight: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                loop: true,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 1,
                    loadOnTransitionStart: true,
                },
                centerInsufficientSlides: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                breakpoints: {
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                }
            };
            this.slider = new Swiper(this.$refs.slider, options);
            this.slider.on('slideChange', i => this.active = i.realIndex);
            this.slider.on('slideChangeTransitionStart', this.renderActive);
            $watch('active', this.renderActive);
        },
        carouselDot(index) {
            this.slider.slideToLoop(index);
        },
        renderActive() {
            const index = parseInt(document.querySelector('.home-swiper-slide.swiper-slide-active').getAttribute("data-swiper-slide-index"));
            const oldActive = document.querySelector('button.carousel-dot.selected');
            if (oldActive)
                oldActive.classList.remove('selected');
            const newActive = document.querySelector('button[data-index=\'' + index + '\'].carousel-dot');
            newActive.classList.add('selected');
        },
    }
}
