window.modal = {
    animation: {
        ['x-transition:enter']: 'ease-out duration-300',
        ['x-transition:enter-start']: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        ['x-transition:enter-end']: 'opacity-100 translate-y-0 sm:scale-100',
        ['x-transition:leave']: 'ease-in duration-200',
        ['x-transition:leave-start']: 'opacity-100 translate-y-0 sm:scale-100',
        ['x-transition:leave-end']: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
    },

    // Versione Definitiva
    openDefinedModal($dispatch, modalName, modalData) {
        const eventName = 'action-' + modalName + '-' + (new Date()).format("yyyy/MM/dd hh:mm:ss");
        const modalDef = modal.def[modalName];
        modalDef.name = modalName;
        modalDef.callbackEvent = eventName;

        return new Promise(function ($return, $error) {
            $dispatch('open-generic-modal', {modalDef, modalData});
            window.addEventListener(eventName, function callback(evt) {
                window.removeEventListener(eventName, callback);
                $return(evt.detail);
            });
        });
    },

    // @Deprecated v1 vecchia
    openModal($dispatch, modalName, modalData) {
        if (modal.def[modalName])
            modal.openDefinedModal($dispatch, modalName, modalData);
        else {
            modalData = modalData || {};
            $dispatch('open-' + modalName, {modalData});
        }
    },
    // @Deprecated v1 vecchia
    openInteractiveModal($dispatch, modalName, modalData) {
        modalData = modalData || {};
        return new Promise(function ($return, $error) {
            let eventName = 'action-' + modalName + '-' + (new Date()).format("yyyy/MM/dd hh:mm:ss");
            $dispatch('open-' + modalName, {
                modalData: {
                    ...modalData,
                    callbackEventName: eventName
                }
            });

            window.addEventListener(eventName, function callback(evt) {
                window.removeEventListener(eventName, callback);
                $return(evt.detail);
            });
        });
    },

    generic: function () {
        let self = null;
        return {
            name: '',
            opened: false,
            animation: modal.animation,

            modalData: {},
            closeTimeout: 0,

            init() {
                self = this;
            },
            open({modalDef, modalData}, $dispatch) {
                this.name = modalDef.name;
                this.mergeDef(modalDef);
                this.modalData = modalData;
                this.opened = true;
                document.body.style.overflow = 'hidden';
                this.startAutoClosable($dispatch);
            },

            callStd(userInteraction, $dispatch) {
                if (this.opened) {
                    const action = this.def.stdInteraction[userInteraction] || userInteraction;
                    this.action(action, $dispatch);
                }
            },
            action(button, $dispatch) {
                clearTimeout(this.closeTimeout);
                if (!this.opened)
                    return;

                if (button.href) {
                    document.location = button.href;
                } else {
                    let eventDetail = {action: button.data};
                    $dispatch(this.def.callbackEvent, eventDetail);
                    this.opened = false;
                    document.body.style.overflow = 'auto';
                }
            },
            startAutoClosable($dispatch) {
                const autoClose = this.def.autoClose;
                if (autoClose && autoClose.timeout > 0) {
                    const self = this;
                    this.closeTimeout = setTimeout(function () {
                        self.action(autoClose.data, $dispatch);
                    }, autoClose.timeout);
                }
            },

            computeBody() {
                const view = this.def.view;
                if (utils.isString(view.body)) {
                    return view.body;
                } else if (utils.isFunction(view.body)) {
                    return view.body(this.modalData);
                } else {
                    return "Err " + this.name;
                }
            },

            def: {view: {}, buttons: [], autoClose: {}},
            mergeDef(modalDef) {
                this.def = {
                    name: modalDef.name,
                    view: {title: modalDef.title, body: modalDef.body},
                    stdInteraction: {
                        X: 'close', esc: 'close', clickAway: 'close'
                    },
                    buttons: [
                        {label: 'Ok', data: null, full: true, href: null}
                    ],
                    autoClose: modalDef.autoClose,
                    callbackEvent: modalDef.callbackEvent
                };

                const def = this.def;
                if (utils.isArray(modalDef.buttons))
                    def.buttons = modalDef.buttons;

                console.log(JSON.parse(JSON.stringify(this.def)));
            }
        };
    },

    single: function (name, defaultModalData) {
        return {
            name: name,
            opened: false,
            modalData: {
                ...defaultModalData,
                callbackEventName: null
            },
            open(modalData) {
                this.opened = true;
                document.body.style.overflow = 'hidden';
                this.modalData = {
                    ...defaultModalData,
                    ...modalData
                };
            },
            action(data, $dispatch) {
                if (!this.opened)
                    return;

                let eventDetail = {name};
                if (utils.isString(data) || utils.isNumber(data) || data instanceof Array || utils.isBoolean(data))
                    eventDetail.action = data;
                else if (data instanceof Object)
                    eventDetail = {...eventDetail, ...data};

                if (this.modalData.callbackEventName)
                    $dispatch(this.modalData.callbackEventName, eventDetail);
                else
                    $dispatch('action-' + name, eventDetail);
                this.opened = false;
                document.body.style.overflow = 'auto';
            },
            close($dispatch) {
                if (!this.opened)
                    return;

                document.body.style.overflow = 'auto';
                $dispatch('single-modal-click', {name});
                this.opened = false;
            },
            animation: modal.animation
        };
    },
    autoClosable(name, timeout) {
        return {
            ...modal.single(name),
            open(modalData, $dispatch) {
                this.opened = true;
                this.modalData = modalData;

                const self = this;
                setTimeout(function () {
                    self.close($dispatch);
                }, timeout);
            }
        }
    },
    chainable: function (name) {
        return {
            name: name,
            opened: false,
            checked: true,
            modalData: {},
            open(modalData) {
                document.body.style.overflow = 'hidden';
                this.opened = true;
                this.checked = true;
                this.modalData = modalData;
            },
            close(userConfirm, $dispatch) {
                if (!this.opened)
                    return;
                document.body.style.overflow = 'auto';
                userConfirm = userConfirm && this.checked;
                $dispatch('chain-modal-click', {userConfirm, name});
                this.opened = false;
            },
            animation: this.animation
        };
    }
};
