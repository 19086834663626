window.editUserProfile = function (profile) {
    return {
        ...window.commonsForm(),

        profile: profile,
        refs: [],
        edited: false,
        v: {},
        init($watch, $dispatch) {
            this.$refs.submitButton.disabled = true;
            const self = this;
            Object.keys(profile).forEach(name => {
                const field = profile[name];
                if (typeof field !== null && field !== null) {
                    const fieldName = field.fieldName;
                    self.v[fieldName] = field.filled ? field.userValue : '';
                    self.refs.push(self.$refs[fieldName]);
                    $watch("v." + fieldName, () => {
                        this.setEdited();
                    })
                }
            });
            setTimeout(function () {
                if (document.querySelector("#hasModified") != null) {
                    modal.openDefinedModal($dispatch, 'successfully-edited-profile', {})
                        .then(() => {
                            document.location.href = "/registrati_benvenuto.jsp"
                        });
                }

                if (document.querySelector("#hasModifiedFiliale") != null) {
                    modal.openDefinedModal($dispatch, 'successfully-edited-profile-filiale', {})
                        .then(() => {
                            document.location.href = "/filiali.jsp"
                        });
                }
            }, 200);
        },
        setEdited() {
            if (!this.edited) {
                this.edited = true;
                this.$refs.submitButton.disabled = false;
                this.$refs.submitButton.classList.remove('read-only');
            }
        },
        editUser($dispatch, event) {
            let modalName = "confirm-edit-profile";
            if (document.querySelector("#brch") != null) {
                modalName += "-filiale";
            }

            modal.openDefinedModal($dispatch, modalName, this.entity)
                .then(detail => {
                    if (detail.action)
                        event.target.submit();
                });
        },
    };
}
