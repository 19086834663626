window.manageBranch = () => {
    return {
        openModal(branchCode, orderWithoutAuthorization, $dispatch) {
            let modalData = {
                branchCode,
                orderWithoutAuthorization
            }

            modal.openModal($dispatch, 'manage-branch-permissions', modalData);
        },
    }
}

window.modal.manageBranches = () => {
    return {
        ...window.modal.single('manage-branch-permissions'),
        message: '',
        modified: false,
        v: {},
        openBranchPermissions(modalData) {
            this.v.order_without_authorization = modalData.orderWithoutAuthorization === 'true';
            this.v.branch_code = modalData.branchCode;
            this.open(modalData);
            this.message = '';
        },
        submit() {
            this.modified = true;
            const data = {
                ...this.v
            };

            fetchPost('/api21/manage-branch/orderWithoutAuthorization', data)
                .then(() => {
                    this.message = '<span class="font-bold">Modifiche salvate</span>';
                })
                .catch(() => {
                    alert("Errore interno");
                })
        },
        closeAndReload($dispatch) {
            this.close($dispatch);
            if (this.modified)
                window.location.reload();
        }
    };
}
