import autoComplete  from "@tarekraafat/autocomplete.js/dist/autoComplete";

document.addEventListener('DOMContentLoaded', function () {
    if (!document.querySelector("#autoCompleteMe"))
        return;

    const autoCompleteJS = new autoComplete({
        data: {
            src: async () => {
                const query = document.querySelector("#autoCompleteMe").value;
                console.log("autoCompleteJS query", query);
                if (!query)
                    return [];
                try {
                    const source = await fetch(`/esSearch?azione=autocomplete&term=${query}`);
                    return await source.json();
                } catch (e) {
                    utils.errorLog(e);
                    return [];
                }
            },
            cache: false
        },
        selector: "#autoCompleteMe",
        observer: true,
        threshold: 2,
        debounce: 300,
        searchEngine: "strict",
        resultsList: {
            element: (list, data) => {
                if (!data.results.length) {
                    setTimeout(autoCompleteJS.close.bind(autoCompleteJS), 1);
                }
            },
            id: "autocomplete_list",
            destination: "#navbar-search",
            position: "afterend",
            tag: "ul",
            maxResults: 5,
            noResults: true,
            class: 'autoComplete_list'
        },
        resultItem: {
            highlight: false,
            element: (item, data) => {
                item.innerHTML = data.match;
            },
            tag: "li",
            class: 'autoComplete_result'
        },
        events: {
            input: {
                keydown(event) {
                    console.log(event);
                    switch (event.keyCode) {
                        case 13:
                            document.querySelector('form#navbar-search-form').submit();
                            break;
                        case 27:
                            autoCompleteJS.input.value = "";
                            autoCompleteJS.close();
                            break;
                    }
                }
            }
        }
    });

    document.querySelector("#autoCompleteMe").addEventListener("selection", function (event) {
        const input = document.querySelector('form#navbar-search-form #autoCompleteMe');
        input.value = event.detail.selection.value
        const form = document.querySelector('form#navbar-search-form');
        form.submit()
    });
});
