// Live Help Chat + Evento TagManager
document.addEventListener('DOMContentLoaded', function () {
    function loadLiveChat() {
        const lh = document.createElement("script");
        lh.type = "text/javascript";
        lh.async = true;
        lh.src = "//server.livehelp.it/widgetjs/72839/181.js?x=" + 1 * new Date();
        const node = document.getElementsByTagName("script")[0];
        node.parentNode.insertBefore(lh, node);
    }

    function overrideLiveChat() {
        if (typeof window.LH_chat === 'undefined') {
            setTimeout(overrideLiveChat, 500);
            return;
        }

        window._LH_chat_original = window.LH_chat;
        window.LH_chat = function () {
            gtmUtils.attivaLiveChat();
            window._LH_chat_original();
        };
    }

    // window.loadLiveChat = loadLiveChat;
    // window.overrideLiveChat = overrideLiveChat;
    loadLiveChat();
    overrideLiveChat();
});
