window.utils = {
    IMAGE_FALLBACK_THUMB: "/fe21/asset/images/general-fallback-111x111.jpg",
    IMAGE_FALLBACK_WEB: "/fe21/asset/images/general-fallback-130x130.jpg",

    errorCatch(reason) {
        console.trace(reason);
        try {
            // Sentry.captureException(reason);
        } catch (e) {
        }
        alert("Errore interno...");
    },
    errorLog(reason) {
        console.trace(reason);
        try {
            // Sentry.captureException(reason);
        } catch (e) {
        }
    },
    errorTrace(reason) {
        console.trace(reason);
    },

    noOp() {
    },

    isEmailValid(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    // isFiscalCodeValid(cf) {
    //     const validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    //     const set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //     const set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //     const set_pari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //     const set_disp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    //
    //     let i, s;
    //     if (cf === '')
    //         return false;
    //     cf = cf.toUpperCase();
    //     if (cf.length !== 16)
    //         return false;
    //     for (i = 0; i < 16; i++) {
    //         if (validi.indexOf(cf.charAt(i)) === -1)
    //             return false;
    //     }
    //     s = 0;
    //     for (i = 1; i <= 13; i += 2)
    //         s += set_pari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    //     for (i = 0; i <= 14; i += 2)
    //         s += set_disp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    //     return s % 26 === cf.charCodeAt(15) - 'A'.charCodeAt(0);
    // },


    resetTextSelection() {
        if (window.getSelection) {
            if (window.getSelection().empty) {  // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {  // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) {  // IE?
            document.selection.empty();
        }
    },

    formatPrice(price) {
        return price.toLocaleString('it-IT', {
            minimumFractionDigits: 2, maximumFractionDigits: 2
        });
    },
    formatOneDecimal(price) {
        return price.toLocaleString('it-IT', {
            minimumFractionDigits: 1, maximumFractionDigits: 1
        });
    },


    isString(o) {
        return typeof o === 'string' || o instanceof String
    },
    isBoolean(o) {
        return typeof o === 'boolean' || o instanceof Boolean
    },
    isNumber(o) {
        return typeof o === 'number' || o instanceof Number
    },
    isFunction(o) {
        return typeof o === 'function' || o instanceof Function
    },
    isArray(o) {
        return Array.isArray(o);
    },
    isObject(o) {
        return !(!o || "object" != typeof o);
    },

    querySelectorAll(selector) {
        return Array.apply(null, document.querySelectorAll(selector));
    },
    addClass(e, name) {
        if (!e || !e.classList) {
            debugger;
            return;
        }
        if (!e.classList.contains(name))
            e.classList.add(name);
    },
    removeClass(e, name) {
        if (!e || !e.classList) {
            debugger;
            return;
        }
        if (e.classList.contains(name))
            e.classList.remove(name);
    },
    toggleClass(e, name) {
        if (!e || !e.classList) {
            debugger;
            return;
        }
        if (e.classList.contains(name)) {
            e.classList.remove(name);
        } else {
            e.classList.add(name);
        }
    },
    setVisible(elem, visible) {
        if (visible)
            utils.show(elem);
        else
            utils.hide(elem);
    },
    show(elems) {
        if (!utils.isArray(elems))
            elems = [elems];
        for (let elem of elems) {
            utils.removeClass(elem, 'hidden');
        }
    },
    hide(elems) {
        if (!utils.isArray(elems))
            elems = [elems];
        for (let elem of elems) {
            utils.addClass(elem, 'hidden');
        }
    },

    isValidInternalReferrer(andItsNotIncludedIn) {
        if (!document.referrer)
            return false;
        if (!document.referrer.startsWith('https://www.ufficiodiscount.it/'))
            return false;

        if (utils.isString(andItsNotIncludedIn)) {
            if (document.referrer.endsWith(andItsNotIncludedIn))
                return false;
        } else if (utils.isArray(andItsNotIncludedIn)) {
            for (let url of andItsNotIncludedIn) {
                if (document.referrer.endsWith(url))
                    return false;
            }
        }

        return true;
    },


    productImageSrc({productCode, imageCount}, size, basename) {
        if (imageCount > 0) {
            if (!basename)
                basename = productCode;

            let src = 'https://www.ufficiodiscount.it/imgpool/';
            src += "/fe21-product-" + size + '/';
            src += productCode.substr(0, 2);
            src += '/';
            src += basename + '.jpg';
            return src;
        } else {
            switch (size) {
                case 'thumb-sq':
                    return utils.IMAGE_FALLBACK_THUMB;
                default:
                    return utils.IMAGE_FALLBACK_WEB;
            }
        }
    },

    scroll: {
        init() {
            try {
                window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                    get: function () {
                        utils.scroll.supportsPassive = true;
                    }
                }));
            } catch (e) {
            }

            utils.scroll.wheelOpt = utils.scroll.supportsPassive ? {passive: false} : false;
            utils.scroll.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
        },
        disableScroll() {
            window.addEventListener('DOMMouseScroll', utils.scroll.preventDefault, false); // older FF
            window.addEventListener(utils.scroll.wheelEvent, utils.scroll.preventDefault, utils.scroll.wheelOpt); // modern desktop
            window.addEventListener('touchmove', utils.scroll.preventDefault, utils.scroll.wheelOpt); // mobile
            window.addEventListener('keydown', utils.scroll.preventDefaultForScrollKeys, false);
        },
        enableScroll() {
            window.removeEventListener('DOMMouseScroll', utils.scroll.preventDefault, false);
            window.removeEventListener(utils.scroll.wheelEvent, utils.scroll.preventDefault, utils.scroll.wheelOpt);
            window.removeEventListener('touchmove', utils.scroll.preventDefault, utils.scroll.wheelOpt);
            window.removeEventListener('keydown', utils.scroll.preventDefaultForScrollKeys, false);
        },
        intercept(target, callback) {
            target || (target = window);
            const interceptor = utils.scroll.interceptEvent(callback);

            target.addEventListener('DOMMouseScroll', interceptor, false); // older FF
            target.addEventListener(utils.scroll.wheelEvent, interceptor, utils.scroll.wheelOpt); // modern desktop
            target.addEventListener('keydown', utils.scroll.interceptEventForScrollKeys(callback), false);

            target.addEventListener('touchstart', utils.scroll.interceptTouchStart(callback), utils.scroll.wheelOpt); // mobile
            target.addEventListener('touchmove', utils.scroll.interceptTouchMove(callback), utils.scroll.wheelOpt); // mobile
            target.addEventListener('touchend', utils.scroll.preventDefault, utils.scroll.wheelOpt); // mobile
        },

        // ------------------------------------
        // modern Chrome requires { passive: false } when adding event
        supportsPassive: false,
        // left: 37, up: 38, right: 39, down: 40,
        // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
        keys: [37, 38, 39, 40, 33, 34, 35, 36],
        upKeys: [38, 33, 36],
        downKeys: [40, 34, 35],
        preventDefault(e) {
            e.preventDefault();
        },
        preventDefaultForScrollKeys(e) {
            if (utils.scroll.keys.includes(e.keyCode)) {
                e.preventDefault();
                return false;
            }
        },
        _touch: {startX: 0, endX: 0, startY: 0, endY: 0},
        interceptTouchStart(callback) {
            return function (e) {
                e.preventDefault();
                utils.scroll._touch = {
                    startX: e.touches[0].clientX, endX: 0,
                    startY: e.touches[0].clientY, endY: 0
                };
                e.dragStart = true;
                callback(e);
            }
        },
        interceptTouchMove(callback) {
            return function (e) {
                e.preventDefault();

                utils.scroll._touch.endX = e.touches[0].clientX;
                utils.scroll._touch.endY = e.touches[0].clientY;
                e.dragY = Math.round(utils.scroll._touch.startY - utils.scroll._touch.endY);
                e.dragX = Math.round(utils.scroll._touch.startX - utils.scroll._touch.endX);
                if (e.dragY === 0 && e.dragY === 0)
                    return;

                callback(e);
            }
        },
        interceptEvent(callback) {
            return function (e) {
                // console.log(`${e.type} ${e.deltaY}`);
                e.isScrollDown = e.deltaY > 0;
                e.preventDefault();
                callback(e);
            }
        },
        interceptEventForScrollKeys(callback) {
            return function (e) {
                if (utils.scroll.keys.includes(e.keyCode)) {
                    e.preventDefault();
                    e.isScrollDown = utils.scroll.downKeys.includes(e.keyCode);
                    callback(e);
                    return false;
                }
            }
        },
    }
}
utils.scroll.init();

window.fetchPost = async function (url = '', data = {}) {
    // Default options are marked with *
    return await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
}
window.fetchForm = async function (url = '', data = {}) {
    const formData = new URLSearchParams();
    Object.keys(data).forEach(k => {
        formData.append(k, data[k]);
    });

    // Default options are marked with *
    return await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            // 'Content-Type': 'application/json'
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: formData
    });
}

Date.prototype.format = function (format) {
    const o = {
        "M+": this.getMonth() + 1, //month
        "d+": this.getDate(),    //day
        "h+": this.getHours(),   //hour
        "m+": this.getMinutes(), //minute
        "s+": this.getSeconds(), //second
        "q+": Math.floor((this.getMonth() + 3) / 3),  //quarter
        "S": this.getMilliseconds() //millisecond
    };

    if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
        (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o) if (new RegExp("(" + k + ")").test(format))
        format = format.replace(RegExp.$1,
            RegExp.$1.length === 1 ? o[k] :
                ("00" + o[k]).substr(("" + o[k]).length));
    return format;
}

String.prototype.toUpperCaseFiscalCode = function () {
    return this.replaceAll(/[àÀ]/g, "A")
        .replaceAll(/[èÈéÉ]/g, "E")
        .replaceAll(/[ìÌ]/g, "I")
        .replaceAll(/[òÒ]/g, "O")
        .replaceAll(/[ùÙ]/g, "U")
        .toUpperCase()
        .replaceAll(/[^A-Z0-9]/g, "");
}
String.prototype.toUpperCaseBillingData = function () {
    return this.replaceAll(/[àÀ]/g, "A'")
        .replaceAll(/[èÈéÉ]/g, "E'")
        .replaceAll(/[ìÌ]/g, "I'")
        .replaceAll(/[òÒ]/g, "O'")
        .replaceAll(/[ùÙ]/g, "U'")
        .toUpperCase();
}
