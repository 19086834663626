import 'alpinejs';

window.megamenu = function () {
    return {
        open: false,
        scrollY: 0,
        windowWidthBreakpoint: 1024,
        subCategoryWidth: 306,
        isMobile: false,
        selectedMacroCategory: null,
        selectedSubCategory: null,
        megaMenuDataJson: null,
        isLoading: false,
        subCategoryHtml: "",
        subGroupHtml: "",
        menu_animation: {
            ['x-transition:enter']: 'transition ease-out duration-100',
            ['x-transition:enter-start']: 'transform opacity-0 scale-95',
            ['x-transition:leave']() {
                return 'transition ease-in duration-75';
            },
            ['x-transition:leave-end']() {
                return 'transform opacity-0 scale-95';
            }
        },
        init() {
            this.isMobile = window.innerWidth < this.windowWidthBreakpoint;
            this.retriveMegaMenuData();
        },
        resize() {
            this.isMobile = window.innerWidth < this.windowWidthBreakpoint;
        },
        openMegaMenu() {
            this.toggleGrayLayer(true);
            this.open = true;
        },
        retriveMegaMenuData() {
            if (!this.megaMenuDataJson) {
                this.megaMenuDataJson = []
                this.isLoading = true;
                try {
                    fetch('/---megamenu.json')
                        .then(res => res.json())
                        .then(res => {
                            Object.keys(res).forEach(key => {
                                if (res !== null) {
                                    this.megaMenuDataJson[key] = res[key];
                                }
                            });
                            this.isLoading = false;
                        })
                } catch (e) {
                    this.isLoading = false;
                    utils.errorLog(e);
                }
            }
        },
        backButton() {
            if (this.selectedSubCategory && this.selectedSubCategory !== "") {
                this.selectedSubCategory = null;
                this.$refs["subgroup-container"].style.setProperty("display", "none");
            } else if (this.selectedMacroCategory && this.selectedMacroCategory !== "") {
                this.selectedMacroCategory = null;
                this.$refs["subcategory-container"].style.setProperty("display", "none");
            }
        },
        toggleGrayLayer(enable) {
            if (enable) {
                document.querySelector(".layer-menu").style.setProperty("display", "block");
            } else {
                document.querySelector(".layer-menu").style.setProperty("display", "none");
            }
        },
        mouseOverMacro($e) {
            this.toggleGrayLayer(true);
            if (this.isMobile && $e.type === "mouseenter") {
                return;
            }
            let moveLeftPx = this.$refs["mega-menu"].offsetWidth;
            if (this.isMobile) {
                $e.preventDefault();
                moveLeftPx = 0;
            }
            if (this.selectedMacroCategory !== $e.target.dataset.macroCategory) {
                this.selectedMacroCategory = $e.target.dataset.macroCategory;
                this.selectedSubCategory = null;
                this.$refs["subgroup-container"].style.setProperty("display", "none");
                this.generateSubCategoryHtml(this.selectedMacroCategory, moveLeftPx, $e.target.innerText, $e.target.getAttribute("href"));
            }
        },
        closeMegamenu() {
            this.mouseLeaveEvent();
            this.open = false;
            if (!this.isMobile) {
                if (this.selectedMacroCategory && this.$refs["subcategory-container"]) {
                    this.$refs["subcategory-container"].style.setProperty("display", "none");
                }
                if (this.selectedSubCategory && this.$refs["subgroup-container"])
                    this.$refs["subgroup-container"].style.setProperty("display", "none");
                this.selectedMacroCategory = null;
                this.selectedSubCategory = null;
            }
            this.toggleGrayLayer(false);
        },
        mouseOverSubCategory($e) {
            if (this.selectedSubCategory !== $e.target.dataset.subGroup) {
                document.querySelectorAll(".selected-sub-category").forEach(el => el.classList.remove('selected-sub-category'));
                if (this.isMobile && $e.type === "mouseenter") {
                    return;
                }

                let moveLeftPx = this.$refs["mega-menu"].offsetWidth + this.subCategoryWidth;
                if (this.isMobile) {
                    $e.preventDefault();
                    moveLeftPx = 0;
                }
                this.selectedSubCategory = $e.target.dataset.subGroup;
                this.generateSubGroupHtml(this.selectedSubCategory, moveLeftPx, $e.target.innerText, $e.target.getAttribute("href"));
                setTimeout((function () {
                    document.querySelector('[data-sub-group="' + $e.target.dataset.subGroup + '"]').classList.add('selected-sub-category');
                }), 50);
            }
        },
        setNoFollow(selectedSubCategory) {
            return selectedSubCategory.startsWith("28") ? 'rel="nofollow"' : "";
        },
        mouseLeaveEvent() {
            clearTimeout(window.megamenuTimer);
        },
        mouseEnterEvent($e) {
            this.mouseLeaveEvent();
            let self = this;
            window.megamenuTimer = setTimeout((function () {
                if ($e.target.dataset.macroCategory !== null &&
                    $e.target.dataset.macroCategory !== undefined) {
                    self.mouseOverMacro($e);
                } else {
                    if ($e.target.dataset.subGroup !== null && $e.target.dataset.subGroup !== undefined
                        && self.selectedSubCategory !== $e.target.dataset.subGroup) {
                        self.mouseOverSubCategory($e);
                    }
                }
            }), 200);
        },
        styleContainer(ref, moveLeftPx) {
            ref.style.setProperty("display", "flex");
            ref.style.setProperty("left", moveLeftPx + "px");
            ref.style.setProperty("min-height", this.$refs["mega-menu"].offsetHeight + "px");
            ref.style.setProperty("width", this.subCategoryWidth + "px");
            ref.style.setProperty("max-height", (window.innerHeight - ref.scrollHeight) + "px");
            ref.style.setProperty("overflow", "auto");
        },
        generateSubCategoryHtml(selectedMacroCategory, moveLeftPx, subCategoryTitle = "", subCategoryLink = "") {
            if (this.$refs["subcategory-container"]) {
                this.subCategoryHtml = "";
                let ref = this.$refs["subcategory-container"];
                this.styleContainer(ref, moveLeftPx);

                let unwrap = JSON.parse(JSON.stringify(this.megaMenuDataJson[selectedMacroCategory]))
                unwrap = unwrap.sort((a, b) => {
                    return a.order - b.order;
                });
                let localHtml = "";
                localHtml += `<span class="flex pl-2 md:pl-4 xl:pl-6 pr-2 items-center text-body-s group bg-Neutrals-Light-Grey" style="min-height: 36px"><span class="text-Main-Dark font-bold text-body-m">${subCategoryTitle}</span>&nbsp;&nbsp;<a class="text-Main-Accent text-body-s underline pr-4" ${this.setNoFollow(selectedMacroCategory)} href="${subCategoryLink}" >Vedi tutto</a></span>`;
                unwrap.forEach((item, index) => {
                    let html = `
    <a href="${item.url}"  @mouseenter="mouseEnterEvent" @mouseleave="mouseLeaveEvent" @click="mouseOverSubCategory" 
    ${this.setNoFollow(selectedMacroCategory)}  
       class="menu-link line-height-xs group justify-between sm:ml-2 selectedcat${this.selectedMacroCategory}" 
         role="menuitem"
       data-sub-group="${item.fkpath}"
       >
        <div class="flex flex-row items-center pointer-events-none space-x-4 max-w-230">
            <img src="${item.icon}" alt="${item.label} icona" class="icon-macro-cat"/>
            <span class="group-hover:text-Main-Primary line-clamp-2">
                ${item.label}
            </span>
        </div>
        <svg class="w-6 h-6 arrow-svg text-Main-Accent stroke-current group-hover:stroke-Primary pointer-events-none group-hover:scale-125"
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18L15 12L9 6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </a>`;
                    localHtml += html;
                });

                this.subCategoryHtml = localHtml;
            }
        },
        generateSubGroupHtml(selected, moveLeftPx, subGroupTitle = "", subGroupLink = "") {
            if (this.$refs["subgroup-container"]) {
                this.subGroupHtml = "";
                let ref = this.$refs["subgroup-container"];
                this.styleContainer(ref, moveLeftPx);
                let unwrap = JSON.parse(JSON.stringify(this.megaMenuDataJson[selected]))
                unwrap = unwrap.sort((a, b) => {
                    return a.order - b.order;
                });
                let localHtml = "";
                localHtml += `<span style="min-height: 36px" class="flex pl-2 md:pl-4 xl:pl-6 pr-2 items-center text-body-s group bg-Neutrals-Light-Grey"><span class="text-Main-Dark font-bold text-body-m">${subGroupTitle}</span>&nbsp;&nbsp;
<a class="text-Main-Accent text-body-s underline" href="${subGroupLink}" ${this.setNoFollow(selected)}>Vedi tutto</a></span>`;
                unwrap.forEach((item, index) => {
                    let html = `
    <a href="${item.url}" ${this.setNoFollow(selected)}
       role="menuitem" class="menu-link line-height-xs group justify-between">
        <div class="flex flex-row items-center pointer-events-none space-x-4">
            <img src="${item.icon}" alt="${item.label} icona" class="icon-macro-cat"/>
            <span class="group-hover:text-Main-Primary line-clamp-2">
                ${item.label}
            </span>
        </div>
    </a>`;
                    localHtml += html;
                });
                document.querySelectorAll('[data-sub-group="' + this.selectedSubCategory + '"]').forEach(el => el.classList.add('selected-sub-category'));
                console.log('[data-sub-group="' + this.selectedSubCategory + '"]')
                this.subGroupHtml = localHtml;
            }
        }
    };
}
