import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

import 'alpinejs';
// HELPERS & UTILS
import'./common/utils'
import'./common/gtm-utils'

// COMMON VANILLA TOOLS
import './common/live-chat'
import './common/google-sign-in'
import './common/search-autosuggest'
import './common/masonry'
import SmoothScroll from 'smooth-scroll';
new SmoothScroll('a[href*="#"]');

// Frontend Server API
import './common/cart-api'

// COMMON ALPINE
import './common/alpine-common'
import './common/alpine-header'
import './common/alpine-helpers'

// MODAL ALPINE
import './common/alpine-modal'
import './common/alpine-modal-define'

// PAGE-SPECIFIC ALPINE
import './alpine-sign-in'
import './alpine-homepage'
import './alpine-category'
import './alpine-product-list'
import './alpine-product-detail'
import './alpine-cart'
import './alpine-checkout22'
import './alpine-payment'
import './pagespeed'
import './alpine-mega-menu'
import './alpine-edit-user-profile'
import './alpine-manage-branches'
import './alpine-order-list'
import './alpine-printers'

