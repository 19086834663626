window.orderList = () => {
    return {
        openModal(orderNumber, totalHumanReadable, $dispatch) {
            let modalData = {
                orderNumber: orderNumber,
                totalHumanReadable: totalHumanReadable
            }

            modal.openModal($dispatch, 'branch-order-approve', modalData);
            console.log("test")
        },
    }
}

window.modal.branchOrderApprove = () => {
    return {
        ...window.modal.single('branch-order-approve'),
        message: '',
        modified: false,
        v: {},
        openBranchPermissions(modalData) {
            this.v.totalHumanReadable = modalData.totalHumanReadable;
            this.v.orderNumber = modalData.orderNumber;
            this.open(modalData);
            this.message = '';
        },
        submit() {
            this.modified = true;
            let orderId = this.v.orderNumber;
            let notes = this.v.authorize_order_Notes;
            this.apiCall(orderId, notes, "autorizza");
        },
        rejectOrder(orderNumber) {
            if (confirm("Sei sicuro di voler rifiutare l'ordine?")) {
                this.modified = true;
                let orderId = orderNumber;
                let notes = null;
                this.apiCall(orderId, notes, "canceled");
                // window.location.reload();
            }
        },
        apiCall(orderId, notes, action) {
            const data = {
                "azione": action,
                "orderId": orderId,
                "note": notes,
            };

            fetchPost('/api21/manage-branch/updateOrdineFiliale', data)
                .then(() => {
                    this.message = '<span class="font-bold">Modifiche salvate</span>';
                })
                .catch(() => {
                    alert("Errore interno");
                })
        },
        closeAndReload($dispatch) {
            this.close($dispatch);
            if (this.modified)
                window.location.reload();
        }
    };
}
